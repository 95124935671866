/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";

import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  CarerTypeDropDown,
  DayTypeDropDown,
  LoadingComponent,
  PayTypeDropDown,
  PreviewCard,
  ReactDataTable,
} from "../../../../components/Component";
import { useState } from "react";
import { PER_PAGE_ITEMS, ServerStatusType } from "../../../../utils";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";
import { ImportExportButton } from "../../../../components/button/ActionButton";
import { listSageRefereceAPI, sageReferenceTableColumns, exportSageRefereceAPI } from "./SageReferenceTableData";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { GradeTypeDropDownWithoutCarer } from "../../../../components/dropdowns/GradeTypeDropDown";
import ImportFileModal from "../../../../components/ImportFileModal/ImportFileModal";

const SageReferenceList = (props) => {
  const location = useLocation();
  const statusData = location?.state?.status;

  const [sageReference, setSageReference] = useState([]);
  const [status, setStatus] = useState();
  const [rateData, setRateData] = useState({
    dayTypeRates: [],
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onImport = () => {
    setImportModalOpen(true);
  };

  useEffect(() => {
    loadSageReference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setSageReference([]);
      setStatus();
    };
  }, []);

  useEffect(() => {
    if (status || rateData) {
      loadSageReference();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, rateData, currentPage]);

  function onExport() {
    setLoading(true);
    let params = {};

    exportSageRefereceAPI(params, (data, error) => {
      if (error === null) {
        window.open(data.fileUrl, "default");
      }
      setLoading(false);
    });
  }

  function loadSageReference() {
    if (isCheckToList(UserPermissionType.grade)) {
      setLoading(true);
      let params = {
        pageInfo: {
          pageIndex: currentPage,
          itemsPerPage: PER_PAGE_ITEMS,
        },
        status: status?.value ?? statusData?.value ?? ServerStatusType.active,
        carerTypeId: rateData?.carerType?.id,
        gradeId: rateData?.grade?.id,
        dayTypeId: rateData?.dayType?.id,
        payType: rateData?.payType?.value,
      };
      listSageRefereceAPI(params, (list, pageInfo, error) => {
        if (error === null) {
          setSageReference(list);
          if (currentPage === 1) {
            setTotalItems(pageInfo?.totalItems ?? 0);
          }
        }
        setLoading(false);
      });
    }
  }

  // API----------------------------------------------------------->
  // const callCarerListApi = () => {
  //   scrollToTop();
  //   if (isCheckToList(UserPermissionType.carer)) {
  //     let params = {
  //       pageInfo: {
  //         pageIndex: currentPage,
  //         itemsPerPage: PER_PAGE_ITEMS,
  //       },
  //       sorting: {
  //         field: sortId ?? null,
  //         order: sortOrder ?? null,
  //       },
  //       search: searchText ?? null,
  //       status: status?.value ?? statusData?.value ?? ServerStatusType.active,
  //       // isCompliant: isCompliant === "YES" ? true : false,
  //     };
  //     if (isCompliant?.value === "YES") {
  //       params.isCompliant = true;
  //     } else if (isCompliant?.value === "NO") {
  //       params.isCompliant = false;
  //     } else {
  //       params.isCompliant = "";
  //     }
  //     listSageRefereceAPI(params, (data, error) => {
  //       if (error === null) {
  //         setSageReference(data?.list);
  //         if (currentPage === 1) {
  //           setTotalItems(data?.pageInfo?.totalItems ?? 0);
  //         } else {
  //           showErrorToast(error.message);
  //         }
  //         setInitScreen(false);
  //       }
  //     });
  //   } else {
  //     setInitScreen(false);
  //   }
  // };

  return (
    <React.Fragment>
      <Head title="Sage Reference" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Sage Reference</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  {/* <label>Carer Type</label> */}
                  <CarerTypeDropDown
                    isShowAll
                    value={rateData?.carerType ?? ""}
                    placeholder={"Carer Type"}
                    onSelect={(item) => {
                      if (item.id !== rateData?.carerType?.id) {
                        setRateData((prev) => ({ ...prev, carerType: item, speciality: null, grade: null }));
                      }
                    }}
                    selectedId={rateData?.carerType?.id}
                    className="w-200px"
                  />
                </span>
                <span className="me-2">
                  <GradeTypeDropDownWithoutCarer
                    isShowAll
                    value={rateData?.grade ?? ""}
                    className="w-225px"
                    placeholder={"Grade Type"}
                    selectedId={rateData?.grade?.id}
                    onSelect={(selectedGrade) => {
                      setRateData((prev) => ({
                        ...prev, // Preserve all previous data
                        grade: selectedGrade, // Only replace grade value
                      }));
                    }}
                  />
                </span>
                <span className="me-2">
                  <PayTypeDropDown
                    isShowAll
                    value={rateData?.payType}
                    selectedValue={rateData?.payType}
                    placeholder={"Pay Type"}
                    onSelect={(payType) => {
                      setRateData((prev) => ({ ...prev, payType: payType }));
                    }}
                    className="w-200px"
                  />
                </span>
                <span className="me-2">
                  <DayTypeDropDown
                    isShowAll
                    value={rateData?.dayType}
                    placeholder={"Day Type"}
                    selectedValue={rateData?.dayType}
                    onSelect={(dayType) => {
                      setRateData((prev) => ({ ...prev, dayType: dayType }));
                    }}
                    className="w-200px"
                  />
                </span>
                {/* <span className="me-2">
                  <StatusActiveDropDown
                    value={status}
                    className="w-100px"
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span> */}

                {isAllowToAccess(UserPermissionType.carer, UserActionType.import) && (
                  <span className="me-2">
                    <ImportExportButton title={"Import"} iconName={"arrow-down"} onClick={onImport} />
                  </span>
                )}
                {isAllowToAccess(UserPermissionType.carer, UserActionType.export) && (
                  <span className="me-2">
                    <ImportExportButton
                      title={"Export"}
                      onClick={() => onExport()}
                      iconName={"arrow-up"}
                      loading={loading}
                    />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={sageReference}
              isAllowed={isCheckToList(UserPermissionType.grade)}
              columns={sageReferenceTableColumns()}
              pagination
              totalItemCount={totalItems}
              selectableRowsNoSelectAll
              onPageChanged={(page, count) => {
                setCurrentPage(page);
              }}
            />
          </PreviewCard>
        </Block>

        <LoadingComponent isLoading={loading} />
      </Content>

      <ImportFileModal
        isOpen={importModalOpen}
        toggle={() => setImportModalOpen(!importModalOpen)}
        title="Sage Reference"
        // callCarerListApi={callCarerListApi}
        importType="SageReference"
      />
    </React.Fragment>
  );
};

export default SageReferenceList;
