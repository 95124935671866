import React, { useEffect, useRef, useState } from "react";
import Content from "../../../../layout/content/Content";
import {
  ActionButton,
  BackButton,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  CarersDropDown,
  Col,
  FormInput,
  HospitalDropDown,
  Icon,
  IconButton,
  LoadingComponent,
  MoreOptions,
  OverlineTitle,
  PreviewAltCard,
  RotaStatusDropDown,
  Row,
  ShiftDropDown,
  Sidebar,
  StatusBadge,
  TimesheetDropdown,
  TooltipComponent,
  UserAvatar,
} from "../../../../components/Component";
import Head from "../../../../layout/head/Head";
import { NavigationTabs } from "../../../../components/navigationTabs/NavigationTabs";
import JobList, { JobType } from "../../jobs/JobList";
import NoteList, { NoteUserType } from "../../notes/NoteList";
import InvoiceList, { InvoiceType } from "../../invoices/InvoiceList";
import { exportInvoiceList, invoiceData } from "../../invoices/InvoiceTableData";
import RateList from "../../rates/RatesList";
import { useHistory, useLocation } from "react-router";
import WardList from "./ward/WardList";
import { fileImportWardApi, wardTableColumns } from "./ward/WardData";
import { formatUKPhoneNumber, openLinkInNewTab, showErrorToast, toCapitalize } from "../../../../utils/Functions";
import { TooltipButton, exportWardAPI, fileImportRateApi, getHospitalDetailAPI } from "./HospitalTableData";
import { exportRateAPI, ratesTableData } from "../../rates/RatesData";
import AnnsBadge from "../../../../components/badge/AnnsBadge";
import { AppText } from "../../../../utils";
import {
  InvoiceStatusOption,
  JobStatusType,
  TimeSheetType,
  TimesheetStatusType,
  bytesToMegaBytes,
} from "../../../../utils/Utils";
import RotaList, { RotaType } from "../../rotas/RotaList";
import RotaListing from "../../rotas/RotaCalendar";
import { ImportExportButton } from "../../../../components/button/ActionButton";
import { GradeTypeDropDownWithoutCarer } from "../../../../components/dropdowns/GradeTypeDropDown";
import DateRangePicker from "../../../../components/input/date-time/DateRangePicker";
import Timesheets from "../../timesheets/Timesheets";
import ImportFileModal from "../../../../components/ImportFileModal/ImportFileModal";
import SendEmailModal from "../../invoices/invoiceEmail/SendEmailModal";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";
import { InvoiceStatusDropDown } from "../../../../components/dropdowns/InvoiceStatusDropDown";
import AuditTrailDatatable from "./AuditTrailDataTable";
import { ButtonGroup } from "reactstrap";

const HospitalDetail = () => {
  const location = useLocation();
  const rateListRef = useRef(null); // Initialize the ref with null
  const wardListRef = useRef(null); // Initialize the ref with null
  const hospitalId = location.state?.data;
  const history = useHistory();
  const invoiceRef = useRef(null);

  const [activeTab, setActiveTab] = useState(0);
  const [activeButton, setActiveButton] = useState(0); // 0 corresponds to the index of the first button
  const [status, setStatus] = useState({ value: JobStatusType.open });

  const [initScreen, setInitScreen] = useState(true);
  const [showModal, setShowModal] = useState({
    note: false,
    rate: false,
    ward: false,
    email: false,
  });
  const [loading, setLoading] = useState(false);
  const [importRatesLoading, setImportRatesLoading] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [isSwitchOn, setSwitchOn] = useState(false);
  const [hospitalDetail, setHospitalDetail] = useState();
  const [sideBar, setSidebar] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [current, setCurrent] = useState(1);
  const [grade, setGrade] = useState();
  const [searchText, setSearchText] = useState();
  const emailList = hospitalDetail?.personFields?.map((item) => item?.personEmail);
  const [totalItem, setTotalItems] = useState();
  const [timeSheetStatus, setTimeSheetStatus] = useState({
    label: toCapitalize(TimesheetStatusType.pending),
    value: TimesheetStatusType.pending,
  });
  const [invoiceStatus, setInvoiceStatus] = useState({ id: 1, label: "Pending", value: "PENDING" });
  const [startDate, setStartDate] = useState();
  const [shiftValue, setShiftValue] = useState();
  const [rotaStatus, setRotaStatus] = useState({ id: 1, label: "Open", value: "OPEN" });
  const [endDate, setEndDate] = useState();
  const [carer, setCarer] = useState();
  const [client, setClient] = useState();
  const [loadingExport, setLoadingExport] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const timesheetRef = useRef(null);

  // const val = {
  //   ...InvoiceStatusOption,
  // };

  // const invoiceStatusObj = Object.values(val);

  const val = {
    ...JobStatusType,
    all: "ALL",
  };
  const statusValues = Object.values(val);

  const getTotalItem = (totalItem) => {
    // the callback. Use a better name
    setTotalItems(totalItem);
  };
  const handleDatePickerChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleSwitchToggle = () => {
    setSwitchOn(!isSwitchOn);
    if (isSwitchOn) {
      setStartDate("");
      setEndDate("");
    }
  };

  const handleButtonClick = (item) => {
    const newValue = item === "ALL" ? "" : item;
    setActiveButton(statusValues.findIndex((valueItem) => valueItem === item));
    setStatus({ value: newValue });
  };

  const onImport = () => {
    setImportModalOpen(true);
  };

  useEffect(() => {
    setStartDate();
    setEndDate();
    setGrade();
  }, [activeTab]);

  useEffect(() => {
    return () => {
      // setActiveTab(0);
      setShowModal();
      setHospitalDetail();
      setSidebar();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hospitalId) {
      loadHospitalDetail(hospitalId);
    }
  }, [hospitalId]);

  function exportInvoice(ev) {
    if (!startDate || !endDate) return;
    setLoadingExport(true);
    let params = {
      startDate: startDate,
      endDate: endDate,
      clientId: client?.id,
      carerId: carer?.id,
      // status: status?.value ?? WageStatusOption.pending,
    };

    exportInvoiceList(params, (data, error) => {
      if (error === null) {
        openLinkInNewTab(data.fileUrl, ev);
      }
      setLoadingExport(false);
    });
  }
  const tabs = [
    {
      text: "Ward",
      icon: "briefcase",
      content: (
        <>
          <div className="px-4 px-xxl-0">
            <WardList
              ref={wardListRef}
              hospitalId={hospitalId}
              wardList={wardTableColumns}
              isOpenAddModel={showModal?.ward}
              onCloseModel={() => setShowModal({ ward: false })}
            />
          </div>
        </>
      ),
      addButton: true,
    },
    {
      text: "Job",
      icon: "network",
      content: (
        <>
          <div className="d-flex justify-content-between mt-2 align-center px-4 px-xxl-0">
            <div className="">
              <span className="text-dark fs-3 fw-bold">Jobs</span>
              <br />
              <AnnsBadge label={"Completed Jobs"} className={"bg-success text-white carehomebadge w-100"} />
            </div>
            <div>
              <ul className="nk-block-tools g-2 d-flex flex-wrap justify-content-end">
                <li>
                  <FormInput
                    inputClass={"padding-important"}
                    isShowLabel={false}
                    value={searchText ?? ""}
                    placeholder="Request ID"
                    onTextChange={(text) => setSearchText(text)}
                  />
                </li>
                <li>
                  <ButtonGroup>
                    {statusValues
                      ?.filter((item) => item !== "CANCELLED")
                      .map((item, index) => (
                        <>
                          <Button
                            key={index}
                            // key={item.value}
                            className={`btn-dim btn-white border-right border
                             ${
                               activeButton === statusValues?.findIndex((valueItem) => valueItem === item)
                                 ? "bg-purple btn-lighter text-white"
                                 : "bg-white text-gray"
                             }`}
                            onClick={() => handleButtonClick(item)}
                          >
                            {toCapitalize(item)}
                          </Button>
                        </>
                      ))}
                  </ButtonGroup>
                </li>
                <li>
                  <GradeTypeDropDownWithoutCarer
                    value={grade}
                    isClearable
                    isShowAll
                    className="w-225px"
                    placeholder={"Grade Type"}
                    selectedId={grade?.id}
                    onSelect={(selectedGrade) => {
                      setGrade(selectedGrade);
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="clientdetail-timesheet px-4 px-xxl-0">
            <JobList
              parent={hospitalDetail}
              type={JobType.hospital}
              gradeId={grade?.id}
              searchText={searchText}
              status={status}
            />
          </div>
        </>
      ),
      addButton: false,
    },
    {
      text: "Rota",
      icon: "flickr-round",
      content: (
        <>
          <div className="d-flex justify-content-between mt-4 align-center px-4 px-xxl-0">
            <div>
              <ul className="nk-block-tools g-2 d-flex flex-wrap justify-content-end">
                <li>
                  <GradeTypeDropDownWithoutCarer
                    value={grade}
                    isClearable
                    isShowAll
                    className="w-225px"
                    placeholder={"Grade Type"}
                    onSelect={(selectedGrade) => {
                      setGrade(selectedGrade);
                    }}
                  />
                </li>
                <li>
                  <RotaStatusDropDown
                    value={rotaStatus}
                    isShowAll
                    className="w-125px "
                    onSelect={(e) => {
                      setRotaStatus(e);
                    }}
                  />
                </li>
                <li>
                  <ShiftDropDown
                    className={"w-120px"}
                    isAny={true}
                    value={shiftValue}
                    onSelect={(selectedShift) => {
                      setShiftValue(selectedShift);
                    }}
                  />
                </li>
                <li>
                  <DateRangePicker onDateChange={handleDatePickerChange} />
                </li>
              </ul>
            </div>
          </div>
          <div className="py-4 px-4 px-xxl-0">
            <RotaListing
              clientId={hospitalDetail?.id}
              type={RotaType.hospital}
              startDate={startDate}
              endDate={endDate}
              gradeId={grade?.id}
              status={rotaStatus?.value}
              shiftType={shiftValue}
            />
          </div>
        </>
      ),
      addButton: false,
    },
    {
      text: "Timesheet",
      icon: "table-view",
      content: (
        <>
          <div className="d-flex justify-content-between mt-2 align-center px-4 px-xxl-0">
            <div className="mb-2">
              <span className="text-dark fs-3 fw-bold">Timesheet</span>
              <br />
              {/* <AnnsBadge
                label={totalItem ? totalItem + "Pending Timesheet" : "0 Pending Timesheet"}
                className={"bg-success text-white carehomebadge w-100"}
              /> */}
            </div>
            <div>
              <ul className="nk-block-tools g-2 d-flex flex-wrap justify-content-end">
                <li>
                  <FormInput
                    inputClass={"padding-important"}
                    isShowLabel={false}
                    value={searchText ?? ""}
                    placeholder="Request ID, Hospital, Ward"
                    onTextChange={(text) => setSearchText(text)}
                  />
                </li>
                <li>
                  <TimesheetDropdown
                    value={timeSheetStatus}
                    className="w-225px"
                    onSelect={(selectedStatus) => {
                      setTimeSheetStatus(selectedStatus);
                    }}
                  />
                </li>
                <li className="d-flex align-items-center">
                  Week
                  <label className="switch ms-1 me-1">
                    <input type="checkbox" checked={isSwitchOn} onChange={handleSwitchToggle} />
                    <span className="slider round"></span>
                  </label>
                  ALL
                </li>

                {!isSwitchOn && (
                  <li>
                    <DateRangePicker onDateChange={handleDatePickerChange} />
                  </li>
                )}

                {selectedRows?.length > 0 ? (
                  <li>
                    <span>
                      <MoreOptions
                        className="text-bg-light"
                        options={[
                          {
                            icon: "file-text",
                            text: "Move To Wages",
                            action: () => {
                              // onEdit(row);
                              // setWagesCall(true);
                            },
                          },
                        ]}
                      />
                    </span>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
          <div className="m-n4">
            <Timesheets
              ref={timesheetRef}
              showAll={isSwitchOn}
              startDate={startDate}
              endDate={endDate}
              getTotalItem={getTotalItem}
              searchText={searchText}
              clientId={hospitalDetail?.id}
              type={TimeSheetType.hospital}
              status={timeSheetStatus}
              isShowMoreOption={(rows) => setSelectedRows(rows)}
            />
          </div>
        </>
      ),
    },
    {
      text: "Invoices",
      icon: "file-text",
      content: (
        <>
          <div className="d-flex justify-content-between mt-2 align-center px-4 px-xxl-0">
            <div className="">
              <span className="text-dark fs-3 fw-bold">Invoices</span>
            </div>
            <div>
              <ul className="nk-block-tools g-2 d-flex flex-wrap justify-content-end">
                <li>
                  {" "}
                  {/* <CarersDropDown
                    isClearable
                    value={carer}
                    className="w-225px"
                    onSelect={(e) => {
                      setCarer(e);
                    }}
                  /> */}
                  <InvoiceStatusDropDown
                    value={invoiceStatus}
                    className="w-125px "
                    onSelect={(e) => {
                      setInvoiceStatus(e);
                    }}
                  />
                </li>
                {/* <li>
                  <HospitalDropDown
                    isClearable
                    value={client}
                    className="w-225px"
                    onSelect={(e) => {
                      setClient(e);
                    }}
                  />
                </li> */}
                <li>
                  <DateRangePicker onDateChange={handleDatePickerChange} />
                </li>
                <li>
                  <ActionButton
                    title={"Export"}
                    onClick={exportInvoice}
                    iconName={"arrow-up"}
                    loading={loadingExport}
                  />
                </li>
                <li>
                  {selectedRows?.length > 0 && (
                    <span className="">
                      <MoreOptions
                        className="text-bg-light"
                        options={[
                          {
                            icon: "file",
                            text: "Prepare Invoice",
                            action: () => {
                              invoiceRef.current?.sendPrepared();
                            },
                            hide: invoiceStatus?.value !== InvoiceStatusOption.pending,
                          },
                          {
                            icon: "emails",
                            text: "Send Email",
                            action: () => {
                              invoiceRef.current?.sendEmail();
                            },
                            hide: invoiceStatus?.value !== InvoiceStatusOption.prepared,
                          },
                        ]}
                      />
                    </span>
                  )}
                </li>
              </ul>
            </div>
          </div>
          <div className="px-4 px-xxl-0">
            <InvoiceList
              clientId={hospitalDetail?.id}
              type={InvoiceType.hospital}
              onRowSelectionChange={(rows) => setSelectedRows(rows)}
              selectedRows={selectedRows}
              status={invoiceStatus}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </>
      ),
    },
    {
      text: "Rate",
      icon: "sign-pound",
      content: (
        <>
          <div className="px-4 px-xxl-0">
            <RateList
              ref={rateListRef}
              carerType={hospitalDetail?.isHomeCare}
              rateList={ratesTableData}
              isOpenAddModel={showModal?.rate}
              hospitalId={hospitalId}
              onCloseModel={() => setShowModal({ rate: false })}
            />
          </div>
        </>
      ),
      addButton: true,
    },
    {
      text: "Note",
      icon: "files",
      content: (
        <div className="px-4 px-xxl-0">
          <NoteList
            parent={hospitalDetail}
            type={NoteUserType.hospital}
            isOpenAddModel={showModal?.note}
            hospitalId={hospitalId}
            onCloseModel={() => setShowModal({ note: false })}
          />
        </div>
      ),
      addButton: true,
    },
    {
      text: "Audit Trail",
      icon: "sort-line",
      content: (
        <div className="px-4 px-xxl-0">
          <AuditTrailDatatable clientId={hospitalId} />
        </div>
      ),
      addButton: true,
    },
    // {
    //   text: "History",
    //   icon: "history",
    //   // content: <HistoryList type={HistoryListType.client} />,
    // },
  ]; // State variable to track active tab, default is set to 0 (Jobs)

  const onEdit = (row) => {
    setSelectedItem(row);
    history.push({
      pathname: `${process.env.PUBLIC_URL}/add-hospital`,
      state: { data: hospitalId }, // Pass the selected item as state
    });
  };

  const toggle = () => {
    setSidebar(!sideBar);
  };

  const handleFileSelect = (file, rootIndex) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      // for API
      uploadFile(file, rootIndex);
    }
  };

  const uploadFile = (file, index) => {
    setImportRatesLoading(true);
    if (tabs[activeTab].text === "Rate") {
      fileImportRateApi(file, hospitalId, (data, error) => {
        if (error === null) {
          // Upload was successful, now trigger the rate list API
          if (rateListRef?.current) {
            rateListRef?.current?.reloadRates();
          }
        } else {
          showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
        }
        setImportRatesLoading(false);
      });
    } else {
      fileImportWardApi(file, hospitalId, (data, error) => {
        if (error === null) {
          // Upload was successful, now trigger the rate list API
          if (wardListRef?.current) {
            wardListRef?.current?.reloadWard();
          }
        } else {
          showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
        }
        setImportRatesLoading(false);
      });
    }
  };

  const onAddClick = () => {
    switch (tabs[activeTab].text) {
      case "Rate":
        setShowModal({ rate: true });
        break;
      case "Note":
        setShowModal({ note: true });
        break;
      case "Ward":
        setShowModal({ ward: true });
        break;
      default:
        break;
    }
  };

  function onExport() {
    setLoading(true);
    let params = {
      clientId: hospitalId,
    };

    exportWardAPI(params, (data, error) => {
      if (error === null) {
        window.open(data.fileUrl, "default");
      }
      setLoading(false);
    });
  }

  const goTo = (text) => {
    switch (text) {
      case "Sms":
        // history.push(`${process.env.PUBLIC_URL}/sms`);
        // setShowModal({ rate: true });
        break;
      case "Note":
        setActiveTab(6);
        setShowModal({ ...showModal, note: true });
        break;
      // case "Rate":
      //   setShowModal({ ...showModal, rate: true });
      //   // setShowModal({ rate: true });
      //   break;
      case "Edit":
        history.push({
          pathname: `${process.env.PUBLIC_URL}/add-hospital`,
          state: { data: hospitalId }, // Pass the selected item as state
        });
        break;
      case "Email":
        setShowModal({ ...showModal, email: true });
        break;
      default:
        break;
    }
  };

  function loadHospitalDetail(hospitalId) {
    getHospitalDetailAPI({ clientId: hospitalId }, (data, error) => {
      if (error === null) {
        setHospitalDetail(data);
      }
      setInitScreen(false);
    });
  }

  function onRateDownload() {
    setLoading(true);
    let params = {
      clientId: hospitalId,
    };
    exportRateAPI(params, (data, error) => {
      if (error === null) {
        window.open(data.fileUrl, "default");
      }
      setLoading(false);
    });
  }

  function getTabClassName(current, tabIndex) {
    if (current === tabIndex) {
      return "active-tab-client-detail";
    } else {
      return "pending-tab-client-detail";
    }
  }

  return (
    <React.Fragment>
      <Head title="hospital-detail" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {toCapitalize(hospitalDetail?.name ?? "")}
                <strong className="text-primary small"></strong>
              </BlockTitle>
              {/* <BlockDes className="text-soft">
                <ul className="list-inline">
                  <li>
                    Last booking: <span className="text-base">{"22/07/2023"}</span>
                  </li>
                  <li>
                    Address{" "}
                    <span className="text-base">
                      {" "}
                      {hospitalDetail?.address ?? ""}, {hospitalDetail?.city ?? ""} , {hospitalDetail?.postalCode ?? ""}
                    </span>
                  </li>
                </ul>
              </BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center ">
                {/* <span className="me-2">
                  {tabs[activeTab]?.addButton && tabs[activeTab]?.text === "Rate" && (
                    // <ActionButton title={`Download Template`} size="sm" onClick={onDownload} />
                    <ActionButton
                      title={`Download Template`}
                      size="md"
                      loading={loading}
                      onClick={() => onRateDownload()}
                    />
                  )}
                </span> */}
                {tabs[activeTab]?.text === "Ward" &&
                  isAllowToAccess(UserPermissionType.ward, UserActionType.export) && (
                    <span className="me-2">
                      <ImportExportButton
                        iconName={"arrow-up"}
                        title={"Export"}
                        size="sm"
                        loading={loading}
                        onClick={() => onExport()}
                      />
                    </span>
                  )}
                <span className="me-2">
                  {tabs[activeTab]?.addButton &&
                    (tabs[activeTab]?.text === "Rate" || tabs[activeTab]?.text === "Ward") &&
                    ((tabs[activeTab]?.text === "Rate" &&
                      isAllowToAccess(UserPermissionType.rate, UserActionType.import)) ||
                      (tabs[activeTab]?.text === "Ward" &&
                        isAllowToAccess(UserPermissionType.ward, UserActionType.import))) && (
                      <ImportExportButton
                        title={"Import"}
                        iconName={"arrow-down"}
                        loading={importRatesLoading}
                        onClick={onImport}
                      />
                    )}
                </span>
                <span className="me-2">
                  {((tabs[activeTab]?.addButton &&
                    isAllowToAccess(UserPermissionType.ward, UserActionType.add) &&
                    tabs[activeTab]?.text === "Ward") ||
                    (isAllowToAccess(UserPermissionType.rate, UserActionType.add) &&
                      tabs[activeTab]?.text === "Rate") ||
                    (isAllowToAccess(UserPermissionType.note, UserActionType.add) &&
                      tabs[activeTab]?.text === "Note")) && (
                    <ActionButton title={`Add ${tabs[activeTab].text}`} size="sm" onClick={onAddClick} />
                  )}
                </span>
                <span className="me-2">
                  <BackButton />
                </span>
                {/* <span className="d-xl-none">
                  <IconButton
                    className={`toggle btn-icon btn-trigger ${sideBar && "active"}`}
                    icon="user-list-fill"
                    onClick={toggle}
                  />
                </span> */}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="gy-5">
            <Col className="col-12 col-lg-3 col-xl-3">
              <div className="card-content p-0">
                <PreviewAltCard className="card-inner-group" bodyClass={"p-0"}>
                  <div className="card-inner p-3">
                    <div className="d-flex align-center justify-content-between">
                      <StatusBadge status={hospitalDetail?.isActive} />
                      {/* <Icon
                        name="edit"
                        className="cursor-pointer fs-5"
                        onClick={(row) => {
                          onEdit(row);
                        }}
                      /> */}
                      <div
                        className="job-edit-icon cursor-pointer"
                        onClick={(row) => {
                          onEdit(row);
                        }}
                      ></div>
                    </div>
                    <div className="user-card user-card-s2  mt-xxl-0 p-0">
                      <div className="">
                        <UserAvatar
                          avatarText="useravatar-text"
                          className={"user-avatar-width"}
                          // theme={ColorsArray[Math.floor(Math.random() * ColorsArray?.length)]}
                          customHex={hospitalDetail?.colourHex}
                          text={(hospitalDetail?.name ? hospitalDetail?.name.slice(0, 2) : "  ").toUpperCase()}
                        ></UserAvatar>
                        <AnnsBadge
                          tag="div"
                          className={
                            hospitalDetail?.isHomeCare === "HOSPITAL"
                              ? "ucap my-2 hospitalbadge bg-primary text-white"
                              : "ucap my-2 carehomebadge bg-success text-white"
                          }
                          pill
                          color={"light"}
                          label={hospitalDetail?.isHomeCare}
                        />
                        <h5 className="d-flex align-center justify-content-center client-detail-profile-title mb-0">
                          <span>{toCapitalize(hospitalDetail?.name ?? "")}</span>
                          <span className="ps-1">({hospitalDetail?.abbrCode ?? ""})</span>
                        </h5>
                        <span className="clientdetail-trustname">{hospitalDetail?.trust?.label}</span>
                        <div className="clientdetail-shortline my-2"></div>
                        <div>
                          <span className="me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="20"
                              viewBox="0 0 17 20"
                              fill="none"
                            >
                              <path
                                d="M7.82153 19.8243C7.82153 19.8243 7.82195 19.8247 8.33354 19.1668L8.84512 19.8247C8.5442 20.0587 8.12245 20.0584 7.82153 19.8243Z"
                                fill="#666666"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.33336 5.00011C6.49239 5.00011 5 6.4925 5 8.33347C5 10.1744 6.49239 11.6668 8.33336 11.6668C10.1743 11.6668 11.6667 10.1744 11.6667 8.33347C11.6667 6.4925 10.1743 5.00011 8.33336 5.00011ZM6.66668 8.33347C6.66668 7.41299 7.41285 6.66679 8.33336 6.66679C9.25386 6.66679 10 7.41299 10 8.33347C10 9.25397 9.25386 10.0001 8.33336 10.0001C7.41285 10.0001 6.66668 9.25397 6.66668 8.33347Z"
                                fill="#666666"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.82139 19.8243L8.33339 19.1668C8.84498 19.8246 8.84589 19.8239 8.84589 19.8239L8.84748 19.8227L8.85264 19.8186L8.87089 19.8043C8.88656 19.7919 8.90914 19.7741 8.93823 19.7508C8.99623 19.7044 9.08014 19.6366 9.18614 19.5491C9.39806 19.3741 9.69881 19.1201 10.0589 18.8C10.7779 18.161 11.739 17.2535 12.7028 16.1826C13.6643 15.1144 14.6443 13.8659 15.3879 12.5441C16.1271 11.2299 16.6668 9.78282 16.6668 8.33339C16.6668 6.12324 15.7888 4.00361 14.226 2.44079C12.6632 0.877981 10.5436 0 8.33339 0C6.12324 0 4.00361 0.877981 2.44079 2.44079C0.877981 4.00361 0 6.12324 0 8.33339C0 9.78282 0.539687 11.2299 1.2789 12.5441C2.02246 13.8659 3.00255 15.1144 3.96395 16.1826C4.92777 17.2535 5.88889 18.161 6.60787 18.8C6.96796 19.1201 7.26872 19.3741 7.48063 19.5491C7.58663 19.6366 7.67055 19.7044 7.72855 19.7508C7.75764 19.7741 7.78022 19.7919 7.79589 19.8043L7.81414 19.8186L7.8193 19.8227L7.82139 19.8243ZM3.61932 3.61932C4.86957 2.36906 6.56527 1.66668 8.33339 1.66668C10.1015 1.66668 11.7972 2.36906 13.0475 3.61932C14.2977 4.86957 15.0001 6.56527 15.0001 8.33339C15.0001 9.38398 14.6023 10.5411 13.9353 11.7269C13.2725 12.9051 12.3776 14.0525 11.464 15.0676C10.5528 16.08 9.6389 16.9435 8.95164 17.5544C8.71473 17.765 8.50547 17.9449 8.33339 18.09C8.1613 17.9449 7.95205 17.765 7.71513 17.5544C7.02788 16.9435 6.11397 16.08 5.20278 15.0676C4.28917 14.0525 3.39425 12.9051 2.73154 11.7269C2.0645 10.5411 1.66668 9.38398 1.66668 8.33339C1.66668 6.56527 2.36906 4.86957 3.61932 3.61932Z"
                                fill="#666666"
                              />
                            </svg>
                          </span>
                          <span>
                            {hospitalDetail?.address ?? ""}, {hospitalDetail?.city ?? ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner card-inner-sm p-0">
                    <ul className="btn-toolbar justify-center gx-1">
                      {TooltipButton.map((buttonItem, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            goTo(buttonItem?.text);
                          }}
                        >
                          <Button href={buttonItem.href} className="btn-trigger btn-icon">
                            <TooltipComponent
                              icon={buttonItem.icon}
                              id={"newpassword" + index}
                              text={buttonItem.text}
                            />
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="card-inner py-2">
                    <span className="sub-text align-center justify-content-center">Agency</span>
                    <div className="text-center">
                      {hospitalDetail?.agency?.map((item, index) => (
                        <span key={index} className="text-center">
                          {item?.value}
                          {index !== hospitalDetail.agency.length - 1 ? ", " : null}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="card-inner p-0">
                    <div className={"steps"}>
                      <ul className={""}>
                        <li
                          onClick={() => setCurrent(1)}
                          className={`wizard-margin py-1 align-center justify-content-center hospital-detail-tab-button" ${getTabClassName(
                            current,
                            1
                          )}`}
                        >
                          <div className="align-center justify-content-center">
                            <Icon name="chat" className={"fs-4"} />
                            <span className={`cursor-pointer number" ${getTabClassName(current, 1)}`}>Contact</span>
                          </div>
                        </li>
                        <li
                          onClick={() => setCurrent(2)}
                          className={`wizard-margin py-1 align-center justify-content-center hospital-detail-tab-button" ${getTabClassName(
                            current,
                            2
                          )}`}
                        >
                          <div className="align-center justify-content-center">
                            <svg
                              className="me-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M18 3V18H3V3H18ZM16 5H5V16H16V5ZM15 0V2L1.99997 1.99997L2 15H0V0H15ZM14 11V13H7V11H14ZM14 7V9H7V7H14Z"
                                fill={current === 1 ? "#333" : "#ffffff"}
                              />
                            </svg>
                            <span className={`cursor-pointer number" ${getTabClassName(current, 2)}`}>Other</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="">
                    {current === 1 && (
                      <>
                        {hospitalDetail?.personFields.length > 0 && (
                          <div className="card-inner">
                            {hospitalDetail?.personFields.map((contact, index) => (
                              <React.Fragment key={index}>
                                <Row className="g-2">
                                  <Col size="12 text-center">
                                    <span className="sub-text">Type</span>
                                    <span>{contact?.personType?.label}</span>
                                  </Col>
                                  <Col size="12 text-center">
                                    <span className="sub-text">Name</span>
                                    <span>{contact?.personName}</span>
                                  </Col>
                                  <Col size="12 text-center">
                                    <span className="sub-text">Number</span>
                                    <span>{formatUKPhoneNumber(contact?.countryCode, contact?.phoneNumber)} </span>
                                  </Col>
                                  <Col size="12 text-center">
                                    <span className="sub-text">Email</span>
                                    <span>{contact?.personEmail}</span>
                                  </Col>
                                </Row>
                                {index !== hospitalDetail?.personFields.length - 1 && (
                                  <>
                                    <div className="clientdetail-shortline my-2 d-flex justify-content-center"></div>
                                  </>
                                )}
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                    {current === 2 && (
                      <>
                        {hospitalDetail?.personFields.length > 0 && (
                          <div className="card-inner">
                            <React.Fragment>
                              <Row className="g-2">
                                <Col size="12 text-center">
                                  <span className="sub-text">Invoice Type</span>
                                  <span>
                                    {hospitalDetail?.invoiceTypes?.map((item) => item.label)?.join(", ") ?? "N/A"}
                                  </span>
                                </Col>
                                <Col size="12 text-center">
                                  <span className="sub-text">Area Manager</span>
                                  <span>{hospitalDetail?.branch?.value}</span>
                                </Col>
                                <Col size="12 text-center">
                                  <span className="sub-text">Miles Allowed?</span>
                                  <span>{hospitalDetail?.isMileAllow === true ? "Yes" : "No"} </span>
                                </Col>
                              </Row>
                            </React.Fragment>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {/* <div className="card-inner">
                    <OverlineTitle tag="h6" className="mb-3">
                      Speciality
                    </OverlineTitle>
                    <ul className="g-1 d-flex flex-wrap">
                      {hospitalDetail?.specialityIds.map((speciality, id) => (
                        <span key={id}>
                          <AnnsBadge label={speciality?.label} color="light" />
                        </span>
                      ))}
                    </ul>
                  </div> */}
                </PreviewAltCard>
              </div>
            </Col>
            <Col className="col-12 col-lg-9 col-xl-9">
              <PreviewAltCard className="card-bordered overflow-x-auto  details-css min-height-100">
                <div className="card-aside-wrap" id="user-detail-block">
                  <div className="card-content">
                    <NavigationTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                  </div>
                </div>
              </PreviewAltCard>
            </Col>
          </Row>
        </Block>
      </Content>
      <LoadingComponent isLoading={initScreen} />

      <SendEmailModal
        visible={showModal?.email}
        item={emailList}
        onClosed={() => {
          setShowModal(false);
          //  setSelectedItem();
        }}
        onSaved={() => {
          //  loadBranch();
        }}
      />

      <ImportFileModal
        clientId={hospitalId}
        importType={tabs[activeTab]?.text}
        isOpen={importModalOpen}
        toggle={() => setImportModalOpen(!importModalOpen)}
        title={tabs[activeTab]?.text === "Ward" ? "Wards" : "Rates"}
        onSuccess={(type) => {
          if (type === "Ward") {
            wardListRef?.current?.reloadWard();
          } else if (type === "Rate") {
            rateListRef?.current?.reloadRates();
          }
        }}
      />
    </React.Fragment>
  );
};

export default HospitalDetail;
