import React, { useEffect, useState, useRef } from "react";
import { availableCarersDropDownData } from "./DropDownData";
import { RSelect } from "../Component";

export const AvailableCarersDropDown = ({
  value,
  onSelect,
  handleOnSearch,
  placeholder,
  startDate,
  endDate,
  gradeId,
  clientId,
  daysFor,
  disabled,
  isClearable,
  ...props
}) => {
  const [list, setList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);

  // Ref to store the dropdown container and the current scroll position
  const dropdownRef = useRef(null);

  useEffect(() => {
    return () => {
      setList([]);
      setSearchString();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasMoreData && !disabled) {
      loadCarerList(page, searchString);
    }
    // loadCarerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchString, gradeId, startDate, endDate, clientId, disabled, daysFor]);

  const loadCarerList = (pageNo, searchText) => {
    // !disabled &&
    //   gradeId &&
    //   startDate &&
    //   endDate &&
    //   clientId &&
    //   (daysFor?.length ?? 0) > 0 &&
    //   availableCarersDropDownData(searchString, startDate, endDate, gradeId, clientId, daysFor, (data, error) => {
    //     if (error === null) {
    //       setList(data);
    //     }
    //   });

    if (!gradeId || !startDate || !endDate || !clientId || (daysFor?.length ?? 0) === 0) {
      return;
    }

    setLoading(true);
    availableCarersDropDownData(searchText, startDate, endDate, gradeId, clientId, daysFor, pageNo, (data, error) => {
      if (error === null) {
        if (pageNo === 1) {
          setList(data);
        } else {
          setList((prev) => [...prev, ...data]);
        }
        setHasMoreData(data.length > 0); // Update based on if more data exists
      }
      setLoading(false);
    });
  };

  const handleSearch = (text) => {
    // Avoid clearing the list on unnecessary triggers
    if (text === searchString) return;

    setSearchString(text);
    setPage(1); // Reset to the first page on new search
    setList([]); // Clear the list for new search
    setHasMoreData(true);
  };

  const handleScroll = (e) => {
    // if (
    //   dropdownRef.current &&
    //   dropdownRef.current.scrollTop + dropdownRef.current.clientHeight >= dropdownRef.current.scrollHeight &&
    //   !loading &&
    //   hasMoreData
    // ) {
    setPage((prev) => prev + 1);
    // }
  };

  // Include loader as the last item in the dropdown list
  const combinedList = [
    ...(list ?? []),
    ...(loading ? [{ label: "Loading...", value: "loading", isDisabled: true }] : []),
  ];

  return (
    <RSelect
      ref={dropdownRef}
      options={combinedList}
      placeholder={"Select Carer"}
      value={value}
      isClearable={isClearable}
      onInputChange={handleSearch}
      isDisabled={disabled ?? false}
      onChange={(e) => {
        onSelect(e);
      }}
      onMenuScrollToBottom={handleScroll}
      loading={loading}
      {...props}
    />
  );
};
