import React from "react";
import { AnnsModal, Col, FormInput, FormInputType, RadioButtonOptions } from "../../../../../components/Component";
import { Form } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import { AppText, RadioOptionTypes, showErrorToast, showSuccessToast } from "../../../../../utils";
import { useForm } from "react-hook-form";
import { addWardAPI } from "./WardData";

const AddWard = ({ visible, hospitalId, item, onSaved, onClosed }) => {
  const [wardData, setWardData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if ((wardData?.name?.length ?? 0) === 0 || (wardData?.code?.length ?? 0) === 0) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [wardData]);

  useEffect(() => {
    if (visible) {
      if (item) {
        setWardData({
          ...wardData,
          id: item?.id,
          name: item?.name,
          code: item?.code,
          abbrCode: item?.abbrCode,
          isActive: item?.isActive,
        });
      } else {
        setWardData({ ...wardData, name: "", code: "", abbrCode: "", isActive: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, item]);

  const close = () => {
    onClosed();
    setLoading(false);
    setWardData({});
  };

  const onFormSubmit = () => {
    if (isDisable) return;
    setLoading(true);
    addWardAPI(wardData, hospitalId, (_, error) => {
      if (error === null) {
        let text = "Ward" + (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };
  const { errors, register, handleSubmit } = useForm();

  return (
    <>
      <AnnsModal
        size={"sm"}
        isOpen={visible}
        toggle={() => close()}
        title={item ? "Edit ward Name" : "Add ward Name"}
        onClick={() => onFormSubmit()}
        disabled={isDisable}
        loading={loading}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Ward Name"}
                  type={FormInputType.default}
                  value={wardData?.name}
                  onTextChange={(name) => {
                    setWardData((prev) => ({
                      ...prev,
                      name: name,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Abbreviation Code"}
                  type={FormInputType.default}
                  value={wardData?.abbrCode}
                  onTextChange={(code) => {
                    setWardData((prev) => ({
                      ...prev,
                      abbrCode: code,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Cost Code"}
                  type={FormInputType.default}
                  value={wardData?.code}
                  onTextChange={(code) => {
                    setWardData((prev) => ({
                      ...prev,
                      code: code,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <RadioButtonOptions
                  name="status"
                  optionType={RadioOptionTypes.status}
                  onChange={(index) => {
                    setWardData((prev) => ({ ...prev, isActive: index === 0 }));
                  }}
                  activeIndex={wardData.isActive ? 0 : 1}
                />
              </div>
            </div>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default AddWard;
