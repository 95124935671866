/* eslint-disable react-hooks/exhaustive-deps */
import { AnnsModal, Col, Rating, Row } from "../../../components/Component";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { TimesheetDetailItem, TimesheetDetailItemForComment } from "../../../components/input/DataItem";
import { calculateTotal, showErrorToast, toCapitalize } from "../../../utils/Functions";
import { getTimesheetFullDataAPI } from "./TimesheetData";
import BACKGROUND from "../../../images/Signaturebackground.jpg";
import { TIME_FORMAT_GLOBAL } from "../../../utils";

export const ModalStatus = {
  success: "success",
  pending: "pending",
};

const ClientDetailModal = ({ visible, timesheetClientDetail, item, onClosed }) => {
  const [superVisiorDetail, setSuperVisiorDetail] = useState();

  useEffect(() => {
    if (visible) {
      if (item?.timesheetId) {
        loadSupervisiorDetail();
      }
    }
  }, [visible, item]);

  function loadSupervisiorDetail() {
    let params = {
      timesheetId: item?.timesheetId ?? null,
    };
    getTimesheetFullDataAPI(params, (data, error) => {
      if (error === null) {
        setSuperVisiorDetail(data);
      } else {
        showErrorToast(error.message);
      }
    });
  }

  const close = (result) => {
    onClosed(result);
  };

  return (
    <>
      <AnnsModal
        ModalBodyClassName={"p-0"}
        size={"xl"}
        isOpen={visible}
        toggle={() => close()}
        title={"Timesheet detail"}
        isFooterHide={true}
        headerClassName={"clientDetailModalCss"}
      >
        <Row className={"my-4"}>
          <Col size={"12"} className="mb-2">
            <div className="timeSheetDetailTitle">Shift details</div>
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Shift Date - Day"}
              value={
                <span>
                  {moment(item?.rota?.fromDate).format("DD/MM/YYYY")} -{" "}
                  {toCapitalize(moment(item?.rota?.fromDate).format("dddd"))}
                </span>
              }
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Actual Time"}
              value={
                <span>
                  {item?.startTime ? moment(item?.startTime).format(TIME_FORMAT_GLOBAL) : "N/A"} -{" "}
                  {item?.endTime ? moment(item?.endTime).format(TIME_FORMAT_GLOBAL) : "N/A"}
                </span>
              }
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Shift Planned Time"}
              value={
                <span>
                  {item?.rota?.startTime ? moment(item?.rota?.startTime).format(TIME_FORMAT_GLOBAL) : "N/A"} -{" "}
                  {item?.rota?.endTime ? moment(item?.rota?.endTime).format(TIME_FORMAT_GLOBAL) : "N/A"}
                </span>
              }
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Break"}
              value={item?.breakTime ?? "N/A"}
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Grade"}
              value={item?.grade?.name ?? "N/A"}
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Total Hours"}
              value={calculateTotal({ startTime: item?.startTime, endTime: item?.endTime }, item?.breakTime ?? 0)}
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Request ID"}
              value={item?.refId ?? "N/A"}
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Incharge"}
              value={item?.isIncharge === 1 ? "YES" : "NO"}
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Carer"}
              value={item?.carer?.firstName + " " + item?.carer?.lastName ?? "N/A"}
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Miles"}
              value={item?.totalMiles ?? "N/A"}
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Carer Email"}
              value={item?.carer?.email}
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Sleepover"}
              value={
                <span>
                  {item?.sleepoverStart ? moment(item?.sleepoverStart).format(TIME_FORMAT_GLOBAL) : "N/A"} -{" "}
                  {item?.sleepoverEnd ? moment(item?.sleepoverEnd).format(TIME_FORMAT_GLOBAL) : "N/A"}
                </span>
              }
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Client"}
              value={item?.client?.name ?? "N/A"}
            />
          </Col>

          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"PO"}
              value={item?.poNumber}
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Ward"}
              value={item?.ward?.name ?? "N/A"}
            />
          </Col>
          <Col size={"12"}>
            <TimesheetDetailItemForComment
              valueClassName={"timesheetDetailModalValue"}
              className={"px-4 py-1"}
              label={"Carer Comments"}
              value={superVisiorDetail?.carerComment ?? "N/A"}
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-2"}
              label={"Carer Signature"}
              value={
                <span className="sign-box-css">
                  <img
                    src={superVisiorDetail?.carerSignature?.fileUrl ?? BACKGROUND}
                    alt="Sign"
                    className="sign-box-css"
                  />
                </span>
              }
            />
          </Col>

          <Col size={12} className="px-4">
            <hr className="dotted-line" />
          </Col>

          {/* SUPERVISOR DETAILS */}

          <Col className={"mb-2"} size={"12"}>
            <div className="timeSheetDetailTitle">Supervisor details</div>
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Name"}
              value={superVisiorDetail?.supervisorName ?? "N/A"}
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Ratings"}
              value={<Rating disable={true} type="star" initVal={superVisiorDetail?.ratings} className="my-rating" />}
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-2"}
              label={"Position"}
              value={superVisiorDetail?.supervisorPosition ?? "N/A"}
            />
          </Col>
          {/* <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Initials"}
              value={"N/A"}
            />
          </Col> */}
          <Col size={"12"}>
            <TimesheetDetailItemForComment
              valueClassName={"timesheetDetailModalValue"}
              className={"px-4 py-2"}
              label={"Comment"}
              value={superVisiorDetail?.supervisorComment ?? "N/A"}
            />
          </Col>
          <Col size={"6"}>
            <TimesheetDetailItem
              valueClassName={"timesheetDetailModalValue"}
              labelClassName={"timesheetDetailModalLabel"}
              className={"px-4 py-1"}
              label={"Signature"}
              value={
                <span className="sign-box-css">
                  <img
                    src={superVisiorDetail?.supervisorSignature?.imageUrl ?? BACKGROUND}
                    alt="Sign"
                    className="sign-box-css"
                  />
                </span>
              }
            />
          </Col>
        </Row>
      </AnnsModal>
    </>
  );
};

export default ClientDetailModal;
