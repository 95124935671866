import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { RedirectAs404 } from "../utils";

import Faq from "../pages/others/Faq";
import Terms from "../pages/others/Terms";
import Dashboard from "../pages/tabs/dashboard/Dashboard";
import CarerList from "../pages/tabs/carers/CarerList";
import Wage from "../pages/tabs/invoices/Wage";
import UserList from "../pages/tabs/others/users/UserList";
import GradeList from "../pages/tabs/others/grade/GradeList";
import SpecialityList from "../pages/tabs/others/speciality/SpecialityList";
import HolidayList from "../pages/tabs/others/holiday/HolidayList";
import UserRoleList from "../pages/tabs/others/user-role/UserRoleList";
import NoteTypeList from "../pages/tabs/others/note-type/NoteTypeList";
import BranchList from "../pages/tabs/others/branch/BranchList";
import Rotas from "../pages/tabs/rotas/Rotas";
import AddUserRole from "../pages/tabs/others/user-role/AddUserRole";
import AddCarer from "../pages/tabs/carers/AddCarer";
import AddJob from "../pages/tabs/jobs/AddJob";
import AddNotes from "../pages/tabs/notes/AddNote";
import DocumentTypeList from "../pages/tabs/others/document-type/DocumentTypeList";
import CarerTypeList from "../pages/tabs/others/carer-type/CarerTypleList";
import AddTrust from "../pages/tabs/clients/trust/AddTrust";
import AddHospital from "../pages/tabs/clients/hospital/AddHospital";
import HospitalDetail from "../pages/tabs/clients/hospital/HospitalDetail";
import TrustList from "../pages/tabs/clients/trust/TrustList";
import HospitalList from "../pages/tabs/clients/hospital/HospitalList";
import Job from "../pages/tabs/jobs/Job";
import Invoice from "../pages/tabs/invoices/Invoice";
import CarerDetails from "../pages/tabs/carers/CarerDetails";
import Sms from "../pages/tabs/sms/Sms";
import JobDetail from "../pages/tabs/jobs/JobDetail";
import InvoiceEmail from "../pages/tabs/invoices/invoiceEmail/InvoiveEmail";
import Template from "../pages/tabs/others/template/Template";
import VerifyPassword from "../pages/auth/CheckPassword";
import CreatePassword from "../pages/auth/CreatePassword";
import AgencyList from "../pages/tabs/others/agency/AgencyList";
import AddAgency from "../pages/tabs/others/agency/AddAgency";
import TimesheetNew from "../pages/tabs/timesheets/TimesheetNew";
import JobAuditTrail from "../pages/tabs/jobs/JobAuditTrail";
import CarerDocList from "../pages/tabs/dashboard/CarerDocList";
import AddCustomInvoice from "../pages/tabs/invoices/AddCustomInvoice";
import LoginActivity from "../pages/auth/LoginActivity";
import BroadCast from "../layout/header/SendBroadCast/BroadCast";
import InvoiceAuditDetail from "../pages/tabs/invoices/invoiceEmail/InvoiceAuditDetail";
import SageReferenceList from "../pages/tabs/others/sage-reference/SageReferenceList";

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/*Dashboards*/}
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Dashboard}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/carer-document-list`} component={CarerDocList}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/hospitals`} component={HospitalList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/hospital-detail`} component={HospitalDetail}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/carers`} component={CarerList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/trust`} component={TrustList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/add-carer`} component={AddCarer}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/carer-detail`} component={CarerDetails}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/jobs`} component={Job}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/add-job`} component={AddJob}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/job-detail`} component={JobDetail}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/job-audit-trail`} component={JobAuditTrail}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/rotas`} component={Rotas}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/timesheet`} component={TimesheetNew}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/wages`} component={Wage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invoices`} component={Invoice}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invoices-email`} component={InvoiceEmail}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/add-invoice`} component={AddCustomInvoice}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invoice-audit-detail`} component={InvoiceAuditDetail}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/branches`} component={BranchList}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/users`} component={UserList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/agency`} component={AgencyList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/add-agency`} component={AddAgency}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/grade`} component={GradeList}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/speciality`} component={SpecialityList}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/holidays`} component={HolidayList}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/roles`} component={UserRoleList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/add-userrole`} component={AddUserRole}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/note-type`} component={NoteTypeList}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/carer-type`} component={CarerTypeList}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/document-type`} component={DocumentTypeList}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/add-notes`} component={AddNotes}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/sms`} component={Sms}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/add-trust`} component={AddTrust}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/add-hospital`} component={AddHospital}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/template`} component={Template}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/template1`} component={Template}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/sage-reference`} component={SageReferenceList}></Route>
        {/* <Route exact path={`${process.env.PUBLIC_URL}/template2`} component={Template}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/template3`} component={Template}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/template4`} component={Template}></Route> */}
        <Route exact path={`${process.env.PUBLIC_URL}/check-password`} component={VerifyPassword}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/change-password`} component={CreatePassword}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/login-activity`} component={LoginActivity}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/broadcast`} component={BroadCast}></Route>

        {/*Demo Pages*/}
        <Route exact path={`${process.env.PUBLIC_URL}/pages/terms-policy`} component={Terms}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/pages/faq`} component={Faq}></Route>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
