import moment from "moment";
import { TableTitle } from "../../../components/Component";
import { TIME_FORMAT_GLOBAL, convertDropValue, dateToShow } from "../../../utils";
import { apiService } from "../../../services/api";
import { APIServicePath } from "../../../services/api/api-service-impl";
import { toPageInfo } from "../../_common/DataConverter";
import { JobStatusType, ShiftTypes, Weekdays } from "../../../utils/Utils";
import { JobType } from "./JobList";
import { convertHexToColor, toCapitalize } from "../../../utils/Functions";
import { GradeBadge } from "../../../components/badge/GradeBadge";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../services/permission/permission";

export const jobTableData = (type, status, onEdit, onView, onCopy) => {
  const jobStatus = status ? status : { id: 1, label: "Open", value: "OPEN" };

  return [
    {
      name: <TableTitle title={"Request Id"} />,
      id: "refId",
      selector: (row) => (row?.refId ? row?.refId : "N/A"),
      sortable: true,
      style: {
        cursor: "pointer",
      },
      sortFunction: (rowA, rowB) => 0,
    },
    {
      name: <TableTitle title={"Carer"} />,
      selector: (row) => {
        if (row?.status === "COMPLETED") {
          return (
            <>
              <span>{row?.grade?.label}</span>
              <br />
              <span className="listingsubtext">{row?.ward?.name}</span>
            </>
          );
        } else {
          return (
            <>
              <span>-</span>
            </>
          );
        }
      },
      omit: !type ? true : false,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Client"} />,
      id: "clientName",
      selector: (row) => row?.client?.label,
      omit: type === JobType.hospital ? true : false,
      sortable: true,
      style: {
        cursor: "pointer",
      },
      sortFunction: (rowA, rowB) => 0,

      // grow: 1,
      //wrap: true,
    },
    {
      name: <TableTitle title={"Ward"} />,
      id: "wardName",
      selector: (row) => row?.ward?.name,
      sortable: true,
      omit: type ? true : false,
      style: {
        cursor: "pointer",
      },
      sortFunction: (rowA, rowB) => 0,

      // grow: 1,
      // wrap: true,
    },

    {
      name: <TableTitle title={"Grade"} />,
      id: "grade",
      selector: (row) => (
        <GradeBadge
          label={row?.grade?.label}
          color={`outline-${convertHexToColor(row?.grade?.colourHex)}`}
          onClick={() => onView(row)}
        />
      ),
      sortable: false,
      omit: type ? true : false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Grade/Ward"} />,
      selector: (row) => {
        return (
          <>
            <div onClick={() => onView(row)}>
              <span>{row?.grade?.label}</span>
              <br />
              <span className="listingsubtext">{row?.ward?.name}</span>
            </div>
          </>
        );
      },
      omit: !type ? true : false,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Shift"} />,
      selector: (row) => {
        const startDate = row?.fromDate ? dateToShow(row?.fromDate) : "";
        const shiftType = row?.shiftType?.value;
        const startTime = row?.startTime ? moment(row?.startTime).format(TIME_FORMAT_GLOBAL) : "";
        const endTime = row?.endTime ? moment(row?.endTime).format(TIME_FORMAT_GLOBAL) : "";
        const icon = (
          <img
            src={
              shiftType === "DAY"
                ? require("../../../assets/images/jsTree/shiftIcon/Sun.png")
                : require("../../../assets/images/jsTree/shiftIcon/Moon.png")
            }
          />
        );

        return (
          <div onClick={() => onView(row)}>
            <span>{startDate}</span>
            <br />
            <span>
              {icon}
              <span className="ms-2">
                {startTime} - {endTime}
              </span>
            </span>
          </div>
        );
      },
      omit: !type ? true : false,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Start Date"} />,
      id: "date",
      selector: (row) => {
        const startDate = row?.fromDate ? dateToShow(row?.fromDate) : "";
        return `${startDate}`;
      },
      omit: type ? true : false,
      sortable: true,
      style: {
        cursor: "pointer",
      },
      sortFunction: (rowA, rowB) => 0,
    },
    {
      name: <TableTitle title={"Shift Time"} />,
      selector: (row) => {
        const shiftType = row?.shiftType?.value;
        const startTime = row?.startTime ? moment(row?.startTime).format(TIME_FORMAT_GLOBAL) : "";
        const endTime = row?.endTime ? moment(row?.endTime).format(TIME_FORMAT_GLOBAL) : "";
        const icon = (
          <img
            src={
              shiftType === "DAY"
                ? require("../../../assets/images/jsTree/shiftIcon/Sun.png")
                : require("../../../assets/images/jsTree/shiftIcon/Moon.png")
            }
            // className={className}
            // alt={imageAlt}
          />
        );

        return (
          <span onClick={() => onView(row)}>
            {icon}
            <span className="ms-2">
              {startTime} - {endTime}
            </span>
          </span>
        );
      },
      omit: type ? true : false,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Added On"} />,
      selector: (row) => {
        const addedOnDate = row?.createdDate ? dateToShow(row?.createdDate) : "";
        return `${addedOnDate}`;
      },
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Applied"} />,
      selector: (row) => row?.appliedCarer,
      omit: jobStatus?.value !== JobStatusType.applied ? true : false,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Booked To"} />,
      selector: (row) => row?.booked,
      omit: jobStatus?.value !== JobStatusType.booked ? true : false,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },

    {
      name: <TableTitle />,
      width: "100px",
      cell: (row) => (
        <>
          {isAllowToAccess(UserPermissionType.job, UserActionType.add) && (
            <span className="me-3" onClick={() => onCopy(row)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                  d="M8.14286 5.28571V3.85714C8.14286 2.51027 8.14286 1.83684 8.56129 1.41841C8.97971 1 9.65314 1 11 1H18.1429C19.4897 1 20.1631 1 20.5816 1.41841C21 1.83684 21 2.51027 21 3.85714V11C21 12.3469 21 13.0203 20.5816 13.4387C20.1631 13.8571 19.4897 13.8571 18.1429 13.8571H16.7143M3.85714 21H11C12.3469 21 13.0203 21 13.4387 20.5816C13.8571 20.1631 13.8571 19.4897 13.8571 18.1429V11C13.8571 9.65314 13.8571 8.97971 13.4387 8.56129C13.0203 8.14286 12.3469 8.14286 11 8.14286H3.85714C2.51027 8.14286 1.83684 8.14286 1.41841 8.56129C1 8.97971 1 9.65314 1 11V18.1429C1 19.4897 1 20.1631 1.41841 20.5816C1.83684 21 2.51027 21 3.85714 21Z"
                  stroke="#527097"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          )}
          {isAllowToAccess(UserPermissionType.job, UserActionType.edit) &&
            (row.status === JobStatusType.open || row.status === JobStatusType.applied) && (
              <span onClick={() => onEdit(row)}>
                <div className="job-edit-icon"></div>
              </span>
            )}
        </>
      ),
      style: {
        cursor: "pointer",
        fontSize: "20px",
      },
      omit: type ? true : false,
    },
  ];
};

//***************************API's*********************************/
export function addJobAPI(data, weekDays, enhanceRate, callback) {
  let rateData = [];
  data?.dayTypeRates.map((rateItem, index) => {
    let rates = {
      dayTypeId: rateItem?.dayType?.id,
      chargeRate: rateItem?.chargeRate ? parseFloat(rateItem?.chargeRate ?? 0) : undefined,
      payRate: rateItem?.payRate ? parseFloat(rateItem?.payRate ?? 0) : undefined,
    };
    rateData.push(rates);
  });

  let params = {
    jobId: data?.jobId,
    carerTypeId: data?.carerType.id,
    // noOfCarer: parseInt(data?.noOfCarer),
    shiftType: data?.shiftType?.value,
    fromDate: data.startDate ? moment(data.startDate).format("YYYY-MM-DD") : null,
    toDate: data.toDate ? moment(data.toDate).format("YYYY-MM-DD") : null,
    startTime: data?.startTime ? moment(data?.startTime).format("HH:mm") : null,
    endTime: data?.endTime ? moment(data?.endTime).format("HH:mm") : null,
    days: (weekDays ?? []).filter((weekDay) => weekDay.checked)?.map((weekDay) => weekDay.value),
    description: data?.description,
    poNumber: data?.poNumber,
    assignedCarerIds: data.carers?.id ? [data.carers.id] : [],
    specialityIds: data?.specialities ? data?.specialities?.map((item) => item.id) : [],
    gradeIds: data.grade?.id ? [data.grade.id] : [],
    clientId: data?.client?.id,
    wardId: data?.ward?.id,
    isMoveToRota: data?.isMoveToRota ? true : false,
    agencyId: data?.agency?.id,
    rateData: rateData ?? [],
    enhanceRate: parseFloat(enhanceRate),
  };
  apiService
    .postCall(APIServicePath.saveJob, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function jobCarerAssignAPI(carer, jobDetailId, agency, jobRate, enhanceRate, callback) {
  let rateData = [];
  jobRate?.dayTypeRates.map((rateItem, index) => {
    let rates = {
      dayTypeId: rateItem?.dayType?.id,
      chargeRate: rateItem?.chargeRate,
      payRate: rateItem?.payRate,
    };
    rateData.push(rates);
  });

  let params = {
    jobId: jobDetailId,
    carerId: carer?.id,
    agencyId: agency?.id,
    rateData: rateData,
    enhanceRate: parseFloat(enhanceRate) ?? null,
  };
  apiService
    .postCall(APIServicePath.jobAssignedCarer, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listJobAPI(params, callback) {
  apiService
    .postCall(APIServicePath.jobList, params)
    .then((data) => {
      let list = toJobList(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getJobDetailAPI(params, callback) {
  apiService
    .postCall(APIServicePath.jobDetail, params)
    .then((data) => {
      let detail = toJob(data.data);
      callback(detail, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function jobCarerAPI(params, callback) {
  apiService
    .postCall(APIServicePath.jobCarerList, params)
    .then((data) => {
      // let pageInfo = toPageInfo(data?.pageInfo);
      let object = toJobCarerList(data);
      callback(object, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function checkCarerAvailabilityAPI(params, callback) {
  apiService
    .postCall(APIServicePath.checkCarerAvailability, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function jobAuditTrailAPI(params, callback) {
  apiService
    .postCall(APIServicePath.jobAuditTrail, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
// //***************************Converters*********************************/

function toJobList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toJob(element));
  });
  return list;
}

function toJob(data) {
  let item = {
    jobId: data?.id,
    refId: data?.refId,
    agency: data?.agency,
    booked:
      (data?.booked?.length ?? 0) > 0 ? data?.booked?.map((carer) => carer?.firstName + " " + carer?.lastName) : null,
    carerType: data?.carerType ? convertDropValue(data?.carerType) : null,
    client: data?.client ? convertDropValue(data?.client) : null,
    ward: data?.ward,
    appliedCarer: data?.totalApplied,
    description: data?.description,
    shiftType: data?.shiftType ? ShiftTypes.find((item) => item.value === data?.shiftType) : null,
    // noOfCarer: parseInt(data?.noOfCarerRequired),
    extraData: data?.extraData,
    poNumber: data?.poNumber,
    grade:
      (data?.grades?.length ?? 0) > 0
        ? {
            id: data?.grades[0]?.id,
            value: data?.grades[0]?.name ?? "",
            label: toCapitalize(data?.grades[0]?.name ?? ""),
            dayStart: data?.grades[0]?.dayStart ? moment(data?.grades[0]?.dayStart, "HH:mm:ss").utc().toDate() : null,
            colourHex: data?.grades[0]?.colourHex,
            nightStart: data?.grades[0]?.nightStart
              ? moment(data?.grades[0]?.nightStart, "HH:mm:ss").utc().toDate()
              : null,
          }
        : null,
    specialities: (data?.specialities ?? []).map((speciality) => convertDropValue(speciality)),
    fromDate: data.fromDate ? moment(data.fromDate).format("YYYY-MM-DD") : null,
    toDate: data.toDate ? moment(data.toDate).format("YYYY-MM-DD") : null,
    startTime: data?.startTime ? moment(data?.startTime, "HH:mm:ss").utc().toDate() : null,
    endTime: data?.endTime ? moment(data?.endTime, "HH:mm:ss").utc().toDate() : null,
    days: (data?.days?.daysFor ?? []).map((day) => {
      const foundWeekday = Weekdays.find((weekday) => weekday.value === day);
      return foundWeekday ? foundWeekday.label : "";
    }),
    serverDays: data?.days?.daysFor,
    viewListDays: (data?.days?.daysFor ?? [])
      .map((day) => {
        return day;
      })
      .join(" - "),
    isMoveToRota: data?.isRotaCreated ? true : false,
    jobCreateType: data?.jobCreateType,
    totalApplied: data?.totalApplied,
    totalAssigned: data?.totalAssigned,
    status: data?.status,
    createdDate: data?.createdDate,
    createdBy: (data?.createdBy?.firstName ?? "Anonymous") + " " + (data?.createdBy?.lastName ?? "user"),
    enhanceRate: data?.enhanceRate,
  };

  return item;
}

function toJobCarerList(data) {
  let assigned = (data?.assigned?.list ?? []).map((data) => {
    const assignedCarerData = {
      name: data.firstName + " " + data.lastName,
      phone: "+" + data?.countryCode + "-" + data?.phone,
      email: data?.email,
    };
    return assignedCarerData;
  });
  let applied = (data?.applied?.list ?? []).map((data) => {
    const appliedCarerData = {
      id: data?.id,
      name: data.firstName + " " + data.lastName,
      label: data.firstName + " " + data.lastName,
      phone: "+" + data?.countryCode + "-" + data?.phone,
      email: data?.email,
    };
    return appliedCarerData;
  });
  return { assigned, applied };
}
