import { TableTitle } from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { GradeBadge } from "../../../../components/badge/GradeBadge";
import { convertHexToColor } from "../../../../utils/Functions";

export const sageReferenceTableColumns = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Carer Type"} />,
      selector: (row) => row?.carerType ?? "",
      sortable: false,
    },
    {
      name: <TableTitle title={"Grade"} />,
      selector: (row) => (
        <>
          <GradeBadge
            label={row?.gradeType?.name ?? ""}
            color={`outline-${convertHexToColor(row?.gradeType?.colourHex) ?? ""}`}
          />
        </>
      ),
      sortable: false,
    },

    {
      name: <TableTitle title={"Pay Type"} />,
      selector: (row) => row?.payType ?? "",
      sortable: false,
    },
    {
      name: <TableTitle title={"Day Type"} />,
      selector: (row) => row?.dayType ?? "",
      sortable: false,
    },
    {
      name: <TableTitle title={"Reference No"} />,
      selector: (row) => row?.refNo ?? "",
      sortable: false,
    },
  ];
};

//***************************API's*********************************/

export function exportSageRefereceAPI(params, callback) {
  apiService
    .postCall(APIServicePath.sageReferenceExport, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fileImportSageReferenceApi(fileId, callback) {
  let params = {
    fileId: fileId,
  };
  apiService
    .postCall(APIServicePath.sageReferenceImport, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listSageRefereceAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listSageReference, params)
    .then((data) => {
      let list = toSageList(data?.list);
      callback(list, data.pageInfo, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toSageList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toSage(element));
  });
  return list;
}

function toSage(data) {
  return {
    id: data.id,
    carerType: data?.carerType?.name ?? "",
    dayType: data?.dayType?.name ?? "",
    gradeType: data?.gradeType ?? {},
    payType: data?.payType ?? "",
    refNo: data?.refNo ?? "",
    status: data?.status ?? "",
  };
}
