import { apiService } from "../../services/api";
import { APIServicePath } from "../../services/api/api-service-impl";
import { InvoiceTypes, PER_PAGE_ITEMS, SERVER_DATE_FORMAT, ServerStatusType, convertDropValue } from "../../utils";
import { dateToShow, toCapitalize } from "../../utils/Functions";

export function rolesDropDownData(callback) {
  apiService
    .postCall(APIServicePath.roleList, { status: ServerStatusType.active })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push(convertDropValue(element));
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function trustDropDownData(callback) {
  apiService
    .postCall(APIServicePath.listTrust, { status: ServerStatusType.active })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];

        let types = [];
        element?.invoiceTypes?.forEach((item) => {
          let value = InvoiceTypes.find((obj) => obj.value === item.InvoiceType);
          value && types.push(value);
        });

        list.push({
          id: element.id,
          label: element.name,
          value: element.name,
          invoiceTypes: types,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function managerDropDownData(callback) {
  apiService
    .postCall(APIServicePath.listUser, { status: ServerStatusType.active })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.firstName + " " + element.lastName,
          value: element.firstName + " " + element.lastName,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function documentDropDownData(callback) {
  apiService
    .postCall(APIServicePath.listDocument, { status: ServerStatusType.active })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.title,
          value: element.title,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function branchDropDownData(callback) {
  apiService
    .postCall(APIServicePath.listBranch, { status: ServerStatusType.active })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.name,
          value: element.name,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function carerTypeDropDownData(callback) {
  apiService
    .postCall(APIServicePath.listCarerType, { status: ServerStatusType.active })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.name,
          value: element.name,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function specialityDropDownData(carerTypeId, callback) {
  apiService
    .postCall(APIServicePath.listSpeciality, { status: ServerStatusType.active, carerTypeId: carerTypeId })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push(convertDropValue(element));
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function hospitalDropDownData(searchText = "", pageNo, callback) {
  apiService
    .postCall(APIServicePath.listHospital, {
      search: searchText,
      status: ServerStatusType.active,
      pageInfo: {
        pageIndex: pageNo,
        itemsPerPage: PER_PAGE_ITEMS,
      },
    })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.name ?? element.title,
          value: element.name ?? element.title,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function carersDropDownData(searchText = "", carerTypeId, callback) {
  apiService
    .postCall(APIServicePath.carerList, {
      search: searchText,
      status: ServerStatusType.active,
      carerTypeId: carerTypeId,
      pageInfo: {
        pageIndex: 1,
        itemsPerPage: PER_PAGE_ITEMS,
      },
    })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.firstName + " " + element.lastName,
          value: element.firstName + " " + element.lastName,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function addressDropDownData(pinCode, callback) {
  apiService
    .postCall(APIServicePath.locationList, {
      pinCode: pinCode,
    })
    .then((data) => {
      const formattedData = [];
      data?.nearbyLocations?.forEach((item) => {
        if (item.name !== item.address) {
          formattedData.push({
            label: `${item.name}, ${item.address}`,
            value: item,
          });
        }
      });

      callback(formattedData, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

var AvailableCarersToken = {};
export function availableCarersDropDownData(
  searchText = "",
  startDate,
  endDate,
  gradeId,
  clientId,
  daysFor,
  pageNo,
  callback
) {
  let params = {
    search: searchText,
    status: ServerStatusType.active,
    startDate: dateToShow(startDate, SERVER_DATE_FORMAT),
    endDate: dateToShow(endDate, SERVER_DATE_FORMAT),
    gradeIds: [gradeId],
    clientId: clientId,
    daysFor: daysFor,
    pageInfo: {
      pageIndex: pageNo,
      itemsPerPage: PER_PAGE_ITEMS,
    },
  };

  AvailableCarersToken?.cancel && AvailableCarersToken?.cancel();
  apiService
    .postCallWithCancelSignal(APIServicePath.availableCarer, params, AvailableCarersToken)
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.firstName + " " + element.lastName,
          value: element.firstName + " " + element.lastName,
          email: element.email,
          phone: element?.phone ? "+" + element?.countryCode + "-" + element?.phone : null,
          status: element?.status,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function gradeTypeDropDownData(carerId, callback) {
  apiService
    .postCall(APIServicePath.listGrade, { status: ServerStatusType.active, carerTypeId: carerId })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        let value = {
          id: element?.id,
          value: element?.name ?? "",
          label: toCapitalize(element?.name ?? ""),
          dayStart: element?.dayStart,
          nightStart: element?.nightStart,
        };
        list.push(value);
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function gradeTypeDropDownDataWithoutCarerType(callback) {
  apiService
    .postCall(APIServicePath.listGrade, { status: ServerStatusType.active })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        let value = {
          id: element?.id,
          value: element?.name ?? "",
          label: toCapitalize(element?.name ?? ""),
          dayStart: element?.dayStart,
          nightStart: element?.nightStart,
        };
        list.push(value);
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function poNumberList(searchString, rotaId, callback) {
  apiService
    .postCall(APIServicePath.poNumberList, {
      rotaId: rotaId,
      search: searchString,
    })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element,
          label: element,
          value: element,
        });
      }

      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function noteTypeDropDownData(callback) {
  apiService
    .postCall(APIServicePath.listNoteType, { status: ServerStatusType.active })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.id,
          label: element.name,
          value: element.name,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function wardDropdownData(hospitalId, callback) {
  apiService
    .postCall(APIServicePath.listWard, { clientId: hospitalId })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push({
          id: element.wardId,
          label: element.wardName,
          value: element.wardName,
        });
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function agencyDropDownData(callback) {
  apiService
    .postCall(APIServicePath.agencyList, { status: ServerStatusType.active })
    .then((data) => {
      let list = [];
      for (let index = 0; index < data.list?.length ?? 0; index++) {
        const element = data.list[index];
        list.push(convertDropValue(element));
      }
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
