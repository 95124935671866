import { ro } from "date-fns/locale";
import store from "../../store/store";
import { convertToNotNull } from "../../utils";
export const UserPermissionType = {
  agency: "agency",
  trust: "trust",
  client: "client",
  carer: "carer",
  job: "job",
  rota: "rota",
  timesheet: "timesheet",
  invoice: "invoice",
  wage: "wage",
  user: "user",
  branch: "branch",
  role: "role",
  rate: "rate",
  note: "note",
  grade: "grade",
  speciality: "speciality",
  availability: "availability",
  holiday: "holiday",
  noteType: "note type",
  documentType: "document type",
  carerType: "carer type",
  template: "template",
  ward: "ward",
};

export const UserActionType = {
  add: "add",
  edit: "edit",
  list: "list",
  remove: "remove",
  detail: "detail",
  import: "import",
  export: "export",
  save: "save",
  cancel: "cancel",
  approve: "approve",
  verify: "verify",
  inquery: "in query",
  moveToWages: "move to wages",
  moveToTimesheet: "move to timesheet",
  finalised: "finalise",
  prepare: "prepare",
  sent: "sent",
  setdefault: "set default",
  setavailability: "set availability",
};

export function isAllowToAccess(type, action) {
  let roles = store.getState()?.auth?.sessionUser?.roles;
  if (!roles) return false;
  if (!roles[type]) return false;
  if (!roles[type].find((item) => item === action)) return false;
  return true;
}

export function isCheckToList(type) {
  let roles = store.getState()?.auth?.sessionUser?.roles ?? {};
  if (!roles) return false;
  if (!roles[type]) return false;
  return true;
}

export const menuItems = () => {
  let roles = store.getState()?.auth?.sessionUser?.roles;
  if (!roles) return [];
  let menu = [];

  menu.push({
    text: "",
    link: "/",
  });

  // if (roles[UserPermissionType.trust] || roles[UserPermissionType.client]) {
  //   let submenu = [];
  //   // roles[UserPermissionType.trust] &&
  //   //   submenu.push({
  //   //     text: "Trust",
  //   //     link: "/trust",
  //   //   });

  //   roles[UserPermissionType.client] &&
  //     submenu.push({
  //       text: "Client",
  //       link: "/hospitals",
  //     });

  //   let item = {
  //     text: "Client",
  //     subMenu: convertToNotNull(submenu),
  //   };
  //   menu.push(item);
  // }

  roles[UserPermissionType.client] &&
    menu.push({
      text: "Client",
      link: "/hospitals",
    });

  roles[UserPermissionType.carer] &&
    menu.push({
      text: "Carer",
      link: "/carers",
    });

  roles[UserPermissionType.job] &&
    menu.push({
      text: "Job",
      link: "/jobs",
    });

  roles[UserPermissionType.rota] &&
    menu.push({
      text: "Rota",
      link: "/rotas",
    });

  roles[UserPermissionType.timesheet] &&
    menu.push({
      text: "Timesheet",
      link: "/timesheet",
    });

  // roles[UserPermissionType.wage] &&
  // menu.push({
  //   text: "Wages",
  //   link: "/wages",
  // });

  // roles[UserPermissionType.invoice] &&
  // menu.push({
  //   text: "Invoices",
  //   link: "/invoices",
  // });

  if (roles[UserPermissionType.wage] || roles[UserPermissionType.invoice]) {
    let submenu = [];

    roles[UserPermissionType.wage] &&
      submenu.push({
        text: "Wages",
        link: "/wages",
      });

    roles[UserPermissionType.invoice] &&
      submenu.push({
        text: "Invoices",
        link: "/invoices",
      });

    let item = {
      text: "Invoice",
      subMenu: convertToNotNull(submenu),
    };
    menu.push(item);
  }

  if (
    roles[UserPermissionType.user] ||
    roles[UserPermissionType.agency] ||
    roles[UserPermissionType.branch] ||
    roles[UserPermissionType.role] ||
    roles[UserPermissionType.grade] ||
    roles[UserPermissionType.speciality] ||
    roles[UserPermissionType.holiday] ||
    roles[UserPermissionType.noteType] ||
    roles[UserPermissionType.documentType] ||
    roles[UserPermissionType.carerType] ||
    roles[UserPermissionType.template]
  ) {
    let submenu = [];

    const permissions = [
      { type: UserPermissionType.agency, text: "Agency", link: "/agency" },
      { type: UserPermissionType.branch, text: "Area Manager", link: "/branches" },
      { type: UserPermissionType.carerType, text: "Carer Type", link: "/carer-type" },
      { type: UserPermissionType.documentType, text: "Document Type", link: "/document-type" },
      { type: UserPermissionType.grade, text: "Grade", link: "/grade" },
      { type: UserPermissionType.holiday, text: "Holiday", link: "/holidays" },
      { type: UserPermissionType.noteType, text: "Note Type", link: "/note-type" },
      { type: UserPermissionType.template, text: "Sage Reference", link: "/sage-reference" },
      { type: UserPermissionType.speciality, text: "Speciality", link: "/speciality" },
      { type: UserPermissionType.template, text: "Template", link: "/template" },
      { type: UserPermissionType.trust, text: "Trust", link: "/trust" },
      { type: UserPermissionType.user, text: "User", link: "/users" },
      { type: UserPermissionType.role, text: "User Role", link: "/roles" },
    ];

    permissions.forEach((permission) => {
      if (roles[permission.type]) {
        submenu.push({
          text: permission.text,
          link: permission.link,
        });
      }
    });

    let item = {
      text: "Other",
      subMenu: convertToNotNull(submenu),
    };
    menu.push(item);
  }
  return menu;
};
