import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useHistory } from "react-router";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  FormInput,
  HospitalDropDown,
  Icon,
  MoreOptions,
  PreviewCard,
  TooltipComponent,
} from "../../../components/Component";
import JobList from "./JobList";
import { JobStatusType } from "../../../utils";
import { toCapitalize } from "../../../utils/Functions";
import { ButtonGroup } from "reactstrap";
import { GradeTypeDropDownWithoutCarer } from "../../../components/dropdowns/GradeTypeDropDown";
import SendEmailModal from "../invoices/invoiceEmail/SendEmailModal";
import HospitalHomeCareDropDown from "../../../components/dropdowns/HospitalHomeCareDropDown";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../services/permission/permission";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Job = () => {
  const [status, setStatus] = useState({ value: JobStatusType.open });
  const [clientType, setClientType] = useState();
  const [searchText, setSearchText] = useState();
  const [client, setClient] = useState();
  const [grade, setGrade] = useState();
  const [modal, setModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeButton, setActiveButton] = useState(0); // 0 corresponds to the index of the first button
  const [sm, updateSm] = useState(false);
  const [totalItems, setTotalItems] = useState(); // the lifted state
  const history = useHistory();
  const location = useLocation();
  const statisticsStatus = location?.state?.status;

  useEffect(() => {
    if (statisticsStatus) {
      setActiveButton(statusValues.findIndex((valueItem) => valueItem === statisticsStatus));
      setStatus({ value: statisticsStatus });
    }
  }, [location?.state?.isJobListMove, statisticsStatus]);

  const val = {
    ...JobStatusType,
    all: "ALL",
  };
  const statusValues = Object.values(val);

  const isRowSelected = selectedRows.length > 0;
  const onAddClick = () => {
    history.push(`${process.env.PUBLIC_URL}/add-job`);
  };

  const onEmail = (selectedRows) => {
    setModal(true);
  };

  // let JobStatusTypeV = JobStatusType

  const handleButtonClick = (item) => {
    const newValue = item === "ALL" ? "" : item;
    setActiveButton(statusValues.findIndex((valueItem) => valueItem === item));
    setStatus({ value: newValue });
  };

  const getTotalItem = (totalItem) => {
    // the callback. Use a better name
    setTotalItems(totalItem);
  };

  return (
    <React.Fragment>
      <Head title="Job" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span>
                  <BlockTitle page className={"mb-0"}>
                    Jobs
                  </BlockTitle>
                  <BlockDes>{totalItems} Jobs</BlockDes>
                </span>
              </div>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-2 d-flex flex-wrap justify-content-end">
                    <li>
                      <FormInput
                        inputClass={"padding-important"}
                        isShowLabel={false}
                        value={searchText ?? ""}
                        placeholder="Request ID,Hospital, Ward"
                        onTextChange={(text) => setSearchText(text)}
                      />
                    </li>
                    <li>
                      <ButtonGroup>
                        {statusValues
                          ?.filter((item) => item !== "CANCELLED")
                          .map((item, index) => (
                            <>
                              <Button
                                key={index}
                                // key={item.value}
                                className={`btn-dim btn-white border-right border
                             ${
                               activeButton === statusValues?.findIndex((valueItem) => valueItem === item)
                                 ? "bg-purple btn-lighter text-white"
                                 : "bg-white text-gray"
                             }`}
                                onClick={() => handleButtonClick(item)}
                              >
                                {toCapitalize(item)}
                              </Button>
                            </>
                          ))}
                      </ButtonGroup>
                    </li>
                    <li>
                      <HospitalDropDown
                        isClearable
                        value={client}
                        className="w-225px"
                        onSelect={(selectedClient) => {
                          setClient(selectedClient);
                        }}
                      />
                    </li>
                    <li>
                      <HospitalHomeCareDropDown
                        value={clientType}
                        placeholder={"Client Type"}
                        className="w-125px"
                        onSelect={(e) => {
                          setClientType(e);
                        }}
                      />
                    </li>
                    <li>
                      <GradeTypeDropDownWithoutCarer
                        value={grade}
                        isClearable
                        isShowAll
                        className="w-250px"
                        placeholder={"Grade Type"}
                        selectedId={grade?.id}
                        onSelect={(selectedGrade) => {
                          setGrade(selectedGrade);
                        }}
                      />
                    </li>
                    {/* <li>
                      <ImportExportButton addButton title={"Add"} onClick={() => onAddClick()} iconName={"plus"} />
                    </li> */}
                    {isAllowToAccess(UserPermissionType.job, UserActionType.add) && (
                      <li>
                        <Button href={""} className="btn-icon bg-purple border-0" onClick={() => onAddClick()}>
                          <TooltipComponent icon={"plus"} id={"addjob"} text={"Add"} iconClass={"text-white"} />
                        </Button>
                      </li>
                    )}
                    {isRowSelected && (
                      <li>
                        <span>
                          <MoreOptions
                            className="text-bg-light"
                            options={[
                              {
                                icon: "emails",
                                text: "Email",
                                action: () => {
                                  onEmail(selectedRows);
                                },
                              },
                              {
                                icon: "chat",
                                text: "Sms",
                                action: () => {
                                  // onSms(row);
                                },
                              },
                            ]}
                          />
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"bg-transparent job-list-table"}>
            <div className="tableDatalistingText m-n4">
              <JobList
                clientId={client?.id}
                searchText={searchText}
                gradeId={grade?.id}
                status={status}
                clientType={clientType}
                selectableRows={true}
                onRowSelectionChange={(rows) => setSelectedRows(rows)}
                getTotalItem={getTotalItem}
              />
            </div>
          </PreviewCard>
        </Block>
        <SendEmailModal
          visible={modal}
          item={selectedRows}
          onClosed={() => {
            setModal(false);
          }}
          onSaved={() => {
            //  loadBranch();
          }}
        />
      </Content>
    </React.Fragment>
  );
};

export default Job;
