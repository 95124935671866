import { Storage } from "../services/storage/storage";

//url for production
export var url = "";
if (process.env.NODE_ENV === "development") {
  url = "";
} else {
  url = window.location.host.split("/")[1];
  if (url) {
    url = `/${window.location.host.split("/")[1]}`;
  } else url = process.env.PUBLIC_URL; /// ADD YOUR CPANEL SUB-URL
}

// Logs out user
export const handleSignout = () => {
  Storage.clearAll();
};

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const NewPasswordType = {
  forgotPassword: "forgot password",
  changePassword: "change password",
};

export const PASSWORD_TOOL_TIP = (
  <p className="text-start">
    Password must contain:
    <br />• One capital letter.
    <br />• One lowercase letter.
    <br />• One digit.
    <br />• One special character (e.g. !@#$%&*)
    <br />• at least 8 characters.
  </p>
);

export const ServerStatusType = {
  active: "ACTIVE",
  inActive: "INACTIVE",
  disabled: "DISABLED",
};

// export const ClientType = { Hospital: "HOSPITAL", HomeCare: "HOMECARE" };
export const PayType = [
  // { id: 1, label: "PayU", value: "PAYU" },
  { id: 2, label: "PAYE", value: "PAYE" },
  { id: 3, label: "Umbrella", value: "UMBRELLA" },
  { id: 4, label: "Limited Company", value: "LIMITED COMPANY" },
];

export const DayType = [
  { id: 1, label: "Day", value: "DAY" },
  { id: 2, label: "Weekend", value: "WEEKEND" },
];

export const MONTHS = [
  { id: 1, label: "January", value: "January" },
  { id: 2, label: "February", value: "February" },
  { id: 3, label: "March", value: "March" },
  { id: 4, label: "April", value: "April" },
  { id: 5, label: "May", value: "May" },
  { id: 6, label: "June", value: "June" },
  { id: 7, label: "July", value: "July" },
  { id: 8, label: "August", value: "August" },
  { id: 9, label: "September", value: "September" },
  { id: 10, label: "October", value: "October" },
  { id: 11, label: "November", value: "November" },
  { id: 12, label: "December", value: "December" },
];

export const InvoiceTypes = [
  { id: 1, label: "Per PO", value: "PO_NUMBER" },
  { id: 2, label: "Per Staff Per Week", value: "STAFF" },
  { id: 3, label: "Per Staff Per Ward Cost Centre", value: "WARD_COST_CENTRE" },
  { id: 5, label: "Per Client Per CA", value: "CLIENT_CA" },
  { id: 6, label: "Per Client Per Nurse", value: "CLIENT_NURSE" },
  { id: 7, label: "Per Client Per Charge Nurse", value: "CLIENT_CHARGE_NURSE" },
  { id: 8, label: "Sleepover", value: "SLEEPOVER" },
  { id: 9, label: "Per Staff Per Shift", value: "EMPLOYEE_WISE" },
  { id: 10, label: "Mileage", value: "MILEAGE" },
];

export const ClientTypeOption = [
  { id: 1, label: "Care Home", value: "HOMECARE" },
  { id: 2, label: "Hospital", value: "HOSPITAL" },
];
export const PersonTypes = [
  { id: 1, label: "Contact", value: "CONTACT" },
  { id: 2, label: "Booking", value: "BOOKING" },
  { id: 3, label: "Invoice", value: "INVOICE" },
];

export const AvailbilityOption = ["AM", "PM", "Day (D)", "Night (NG)", "Evening (E)", "Twilight (T)", "Any (A)"];
export const Weekdays = [
  { label: "Monday", value: "MON" },
  { label: "Tuesday", value: "TUE" },
  { label: "Wednesday", value: "WED" },
  { label: "Thursday", value: "THU" },
  { label: "Friday", value: "FRI" },
  { label: "Saturday", value: "SAT" },
  { label: "Sunday", value: "SUN" },
];
export const ShiftCheck = [
  { label: "Day", value: "is_day" },
  { label: "Night", value: "is_night" },
  { label: "Any", value: "is_any" },
  { label: "Not Available", value: null },
];

export const RadioOptionTypes = {
  status: "status",
  hospital: "hospital",
  yesorno: "yesorno",
  jobStatus: "jobStatus",
  rotaCancel: "rotaCancel",
};

export const AuthPaths = [
  "/auth-reset",
  "/auth-login",
  "/auth-create-password",
  "/confirmation",
  "/verify-email",
  "/reset-password",
];

export const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};
export const ShiftTypes = [
  { id: 1, label: "Day", value: "DAY" },
  { id: 2, label: "Night", value: "NIGHT" },
  { id: 3, label: "Any", value: "ANY" },
];

export const JobStatusType = {
  open: "OPEN",
  applied: "APPLIED",
  booked: "BOOKED",
  archived: "ARCHIVED",
  cancelled: "CANCELLED",
};

export const TimesheetStatusType = {
  all: "ALL",
  pending: "PENDING",
  submitted: "SUBMITTED",
  verified: "VERIFIED",
  approved: "APPROVED",
  reject: "IN QUERY",
  cancelled: "CANCELLED",
  finalised: "FINALISED",
};
export const CarerDocumentType = {
  expired: "EXPIRED",
  expiringsoon: "EXPIRING SOON",
};

export const SendNotificationType = {
  timesheet: "TIMESHEET",
  grade: "GRADE",
};

export const ColorsArray = ["green", "info", "blue", "pink", "dark", "gray", "orange", "teal", "purple", "red"];

export const ColorsWithHexCode = [
  { id: 1, label: "green", value: "#1ee0ac" },
  { id: 2, label: "info", value: "#09c2de" },
  { id: 3, label: "blue", value: "#559bfb" },
  { id: 4, label: "pink", value: "#ff63a5" },
  { id: 5, label: "dark", value: "#1f2b3a" },
  { id: 6, label: "gray", value: "#3c4d62" },
  { id: 7, label: "orange", value: "#ffa353" },
  { id: 8, label: "teal", value: "#20c997" },
  { id: 9, label: "purple", value: "#816BFF" },
  { id: 10, label: "red", value: "#e85347" },
];

export const WageStatus = [
  { id: 1, label: "Pending", value: "PENDING" },
  { id: 2, label: "Finalised", value: "FINALISED" },
  // { id: 3, label: "Invoiced", value: "INVOICE_CREATED" },
];

export const InvoiceStatus = [
  { id: 1, label: "Pending", value: "PENDING" },
  { id: 2, label: "Prepared", value: "PREPARED" },
  { id: 3, label: "Sent", value: "SENT" },
];

export const WageStatusOption = {
  pending: "PENDING",
  finalised: "FINALISED",
  // invoiced: "INVOICE_CREATED",
};

export const InvoiceStatusOption = {
  pending: "PENDING",
  prepared: "PREPARED",
  sent: "SENT",
};

export const InvoiceFileType = {
  template: "TEMPLATE",
  invoice: "INVOICE",
  new: "NEW",
};

export const RotaOptions = {
  open: "OPEN",
  cancelled: "CANCELLED",
  completed: "COMPLETED",
};

export const ClientType = {
  isHomeCare: "HOMECARE",
  isHospital: "HOSPITAL",
};

export const TimeSheetType = {
  default: "default",
  hospital: "hospital",
  carer: "carer",
};
export const File_TYPE = { pdf: "PDF", xlxs: "XLXS" };

export const EXPORT_TYPE = [
  { value: "PDF", label: "PDF" },
  { value: "XLXS", label: "XLXS" },
];
