import React, { useEffect, useRef, useState } from "react";
import { hospitalDropDownData } from "./DropDownData";
import { RSelect } from "../Component";

export const HospitalDropDown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  isClearable,
  handleOnSearch,
  placeholder,
  handleOnSelect,
  inputSearchString,
  disabled,
  ...props
}) => {
  const [list, setList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);

  // Ref to store the dropdown container and the current scroll position
  const dropdownRef = useRef(null);

  useEffect(() => {
    return () => {
      setList([]);
      setSearchString();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasMoreData && !disabled) {
      loadHospitalList(page, searchString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, page]);

  const loadHospitalList = (pageNo, searchText) => {
    setLoading(true);
    hospitalDropDownData(searchText, pageNo, (data, error) => {
      if (error === null) {
        if (pageNo === 1) {
          setList(data);
        } else {
          setList((prev) => [...prev, ...data]);
        }
        setHasMoreData(data.length > 0); // Update based on if more data exists
      }
      setLoading(false);
    });
  };

  const handleSearch = (text) => {
    // Avoid clearing the list on unnecessary triggers
    if (text === searchString) return;
    setSearchString(text);
    setPage(1); // Reset to the first page on new search
    setList([]); // Clear the list for new search
    setHasMoreData(true);
  };

  const handleScroll = (e) => {
    setPage((prev) => prev + 1);
  };

  // Include loader as the last item in the dropdown list
  const combinedList = [
    ...(list ?? []),
    ...(loading ? [{ label: "Loading...", value: "loading", isDisabled: true }] : []),
  ];

  return (
    <RSelect
      ref={dropdownRef}
      options={combinedList}
      isMulti={isMulti}
      placeholder={"Search Client"}
      disable={disabled}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onInputChange={handleSearch}
      isClearable={isClearable}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      onMenuScrollToBottom={handleScroll}
      loading={loading}
      {...props}
    />
  );
};
