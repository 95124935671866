import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { DayType } from "../../utils/Utils";

export const DayTypeDropDown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  placeholder,
  selectedValue,
  ...props
}) => {
  const [list, setList] = useState(DayType);

  useEffect(() => {
    if (isShowAll) {
      setList([{ label: "All", value: "" }, ...DayType]);
    } else {
      setList(DayType);
    }
  }, [isShowAll]);

  useEffect(() => {
    if (selectedValue) {
      const selectedOption = list.find((option) => option.value === selectedValue);
      if (selectedOption) {
        onSelect(selectedOption);
      }
    }
  }, [selectedValue, value, list, onSelect]);

  return (
    <RSelect options={list} isMulti={isMulti} placeholder={placeholder} value={value} onChange={onSelect} {...props} />
  );
};
