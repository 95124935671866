import { Icon, StatusBadge, TableTitle, UserAvatar } from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { InvoiceTypes, ServerStatusType, convertDropValue } from "../../../../utils";
import { toContactList, toPageInfo } from "../../../_common/DataConverter";
import moment from "moment/moment";
import { toCapitalize } from "../../../../utils/Functions";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";

export const HospitalTableData = (onEdit, onView) => {
  return [
    {
      name: <TableTitle title={"Name"} filterable={false} />,
      id: "name",
      compact: false,
      sortable: true,
      ignoreRowClick: false,
      cell: (row) => (
        <div onClick={() => onView(row)}>
          <UserAvatar
            className="my-2"
            customHex={row?.colourHex}
            text={(row?.name ? row.name.slice(0, 2) : "  ").toUpperCase()}
            name={toCapitalize(row?.name ?? " ")}
            subName={row?.abbrCode}
            clientType={row?.isHomeCare}
          ></UserAvatar>
        </div>
      ),
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Contact"} />,
      width: "320px",
      style: {
        cursor: "pointer",
        color: "black",
      },
      cell: (row) => {
        const contact = row?.personFields?.find((field) => field?.personType?.value === "CONTACT");
        const countryCode = contact?.countryCode ?? row?.personFields[0]?.countryCode;
        const phoneNumber = contact?.phoneNumber ?? row?.personFields[0]?.phoneNumber;
        const formattedPhoneNumber = countryCode ? `+${countryCode}-${phoneNumber}` : `${phoneNumber}`;
        const email = contact?.personEmail ?? row.personFields[0]?.personEmail;

        return (
          <div className="contact-info" onClick={() => onView(row)}>
            <div className="phone-info">
              <Icon name={"call"} className="me-1 call-email-icon" />
              {formattedPhoneNumber}
            </div>
            <div className="email-info">
              <Icon name={"mail"} className={"me-1 call-email-icon"} />
              {email}
            </div>
          </div>
        );
      },
    },
    {
      name: <TableTitle title={"City"} />,
      id: "city",
      selector: (row) => row?.city,
      sortable: true,
      style: {
        cursor: "pointer",
        color: "black",
      },
    },
    // {
    //   name: <TableTitle title={"Agency"} />,
    //   id: "agency",
    //   cell: (row) => {
    //     const agencyNames = row?.agency.map((item) => <div key={item.id}>{item.value}</div>);
    //     return (
    //       <div className="my-2" onClick={() => onView(row)}>
    //         {agencyNames}
    //       </div>
    //     );
    //   },
    //   // sortable: true,
    //   style: {
    //     cursor: "pointer",
    //     color: "black",
    //   },
    // },
    {
      name: <TableTitle title={"Area Manager"} />,
      id: "areamanager",
      selector: (row) => {
        return (
          <div className="my-2" onClick={() => onView(row)}>
            {row?.branch && ( // Check if branch exists
              <div key={row.branch.id}>{row.branch.value}</div>
            )}
          </div>
        );
      },
      sortable: true,
      style: {
        cursor: "pointer",
        color: "black",
      },
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => <StatusBadge status={row?.isActive} />,
      width: "6rem",
    },
    isAllowToAccess(UserPermissionType.client, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,
      style: {
        cursor: "pointer",
        fontSize: "20px",
      },
      width: "6rem",
      cell: (row) => (
        <span onClick={() => onEdit(row)}>
          <Icon name={"edit"} />
        </span>
      ),
    },
  ];
};

//***************************API's*********************************/
export function addHospitalAPI(data, callback) {
  let params = {
    id: data.id,
    name: data.name,
    abbrCode: data.abbrCode,
    trustId: data.trust?.id,
    branchId: data.branch?.id,
    address: data.address,
    latitude: parseFloat(data?.latitude ?? 0),
    longitude: parseFloat(data?.longitude ?? 0),
    city: data.city,
    postalCode: data?.postalCode,
    isMilesChargeAllow: data?.isMileAllow,
    isInvoiceSpecialGrp: data?.isPrimaryGroup === true ? true : false,
    comCareNo: data.comCareNo,
    refId: data.referenceNo,
    telephone: data.phoneNumber?.phoneNumber,
    countryCode: data.phoneNumber?.dialCode,
    clientType: data.isHomeCare?.value,
    homeManagerIds: data.homeManagers?.id ? [data.homeManagers.id] : [],
    invoiceTypes: data.trust?.id ? [] : data.invoiceTypes?.map((item) => item?.value),
    specialityIds: data.specialityIds?.map((item) => item.id) ?? [],
    agencyIds: data.agency?.map((item) => item.id) ?? [],
    contacts: data.personFields.map((person) => {
      return {
        id: person.personId,
        type: person?.personType?.value,
        name: person.personName,
        phone: person.phone.phoneNumber,
        countryCode: person.phone.dialCode,
        email: person.personEmail,
      };
    }),
    status: data.isActive ? ServerStatusType.active : ServerStatusType.disabled,
  };
  apiService
    .postCall(APIServicePath.addHospital, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listHospitalAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listHospital, params)
    .then((data) => {
      let list = toHospitalList(data?.list);
      // let pageInfo = toPageInfo(data?.pageInfo);
      let pageInfo = data?.pageInfo ? toPageInfo(data?.pageInfo) : null;
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getHospitalDetailAPI(params, callback) {
  apiService
    .postCall(APIServicePath.detailHospital, params)
    .then((data) => {
      let detail = toHospital(data.data);
      callback(detail, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fileImportRateApi(file, clientId, callback) {
  let params = {
    fileId: file,
    clientId: clientId,
  };
  apiService
    .postCall(APIServicePath.importRate, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fileImportClientApi(fileId, callback) {
  let params = {
    fileId: fileId,
  };
  apiService
    .postCall(APIServicePath.importFileClient, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function exportClientAPI(params, callback) {
  apiService
    .postCall(APIServicePath.exportClient, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function clientImportTemplateAPI(callback) {
  apiService
    .postCall(APIServicePath.clientImportTemplate)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function exportWardAPI(params, callback) {
  apiService
    .postCall(APIServicePath.exportWard, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function clientAuditTrailAPI(params, callback) {
  apiService
    .postCall(APIServicePath.clientAuditTrail, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toHospitalList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toHospital(element));
  });
  return list;
}

function toHospital(data) {
  let types = [];
  if (data?.trust) {
    data?.trust?.invoiceTypes?.forEach((item) => {
      let value = InvoiceTypes.find((obj) => obj.value === item.InvoiceType);
      value && types.push(value);
    });
  } else {
    data?.invoiceTypes?.forEach((item) => {
      let value = InvoiceTypes.find((obj) => obj.value === item.InvoiceType);
      value && types.push(value);
    });
  }

  let item = {
    id: data?.id,
    name: data?.name,
    abbrCode: data?.abbrCode,
    address: data?.address,
    latitude: data?.latitude,
    longitude: data?.longitude,
    phoneNumber: data?.telephone ? { phoneNumber: data?.telephone, dialCode: data?.countryCode ?? "44" } : {},
    city: data?.city,
    trust: data?.trust ? convertDropValue(data?.trust) : null,
    branch: data?.branch ? convertDropValue(data?.branch) : null,
    comCareNo: data?.comCareNo,
    referenceNo: data?.refId,
    isHomeCare: data?.clientType,
    homeManagers:
      (data?.homeManagers ?? []).length > 0
        ? {
            id: data.homeManagers[0].id,
            firstName: data.homeManagers[0].firstName,
            lastName: data.homeManagers[0].lastName,
          }
        : null,
    specialityIds: (data?.specialities ?? []).map((speciality) => convertDropValue(speciality)),
    invoiceTypes: types,
    personFields: toContactList(data?.contacts),
    isActive: data.status === ServerStatusType.active ? true : false,
    isMileAllow: data?.isMilesChargeAllow === 0 ? false : true,
    isPrimaryGroup: data?.isInvoiceSpecialGrp === true ? true : false,
    lastBooking: data?.lastBooking?.toDate ? moment(data?.lastBooking?.toDate).format("YYYY-MM-DD") : null,
    nextBooking: data?.lastBooking?.fromDate ? moment(data?.lastBooking?.fromDate).format("YYYY-MM-DD") : null,
    agency: (data?.agencies ?? []).map((agency) => convertDropValue(agency)),
    lastModifiedBy: data?.lastModifiedBy,
    lastModifiedTime: data?.lastModifiedTime,
    colourHex: data?.colourHex,
  };

  return item;
}

// button data

export const TooltipButton = [
  {
    id: 1,
    href: "#sms",
    className: "btn-trigger btn-icon",
    icon: "chat",
    text: "Sms",
  },
  {
    id: 2,
    href: "#add note",
    className: "btn-trigger btn-icon",
    icon: "note-add",
    text: "Note",
  },
  {
    id: 4,
    href: "#email",
    className: "btn-trigger btn-icon",
    icon: "emails",
    text: "Email",
  },
];
