//********************************* NEW TIMESHEET LISTING SCREEN *********************************//
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  Block,
  BreakHourDropdown,
  Col,
  ConfirmationAlert,
  FormCheckbox,
  FormInput,
  FormInputType,
  FormTimePicker,
  Icon,
  LoadingComponent,
  MoreOptions,
  PaginationComponent,
  PreviewAltCard,
  Row,
} from "../../../components/Component";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import ActionButton from "../../../components/button/ActionButton";
import { AppText, PER_PAGE_ITEMS, TIME_FORMAT_GLOBAL, TimesheetStatusType } from "../../../utils";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  calculateTotal,
  covertToNumberOnly,
  scrollToTop,
  showErrorToast,
  showSuccessToast,
  toCapitalize,
} from "../../../utils/Functions";
import {
  addTimesheetAPI,
  getTimesheetListingAPI,
  moderateStatus,
  movetoWagesAPI,
  timesheetCancel,
  timesheetReject,
} from "./TimesheetData";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import ClientDetailModal from "./clientDetailModal";
import QueryModal from "./QueryModal";
import { TimeSheetType, bytesToMegaBytes } from "../../../utils/Utils";
import { fileUploadApi } from "../carers/CarerData";
import QueryReasonModal from "./QueryReasonModal";
import { forwardRef } from "react";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../services/permission/permission";

const Timesheets = forwardRef(function Timesheets(
  { type, startDate, endDate, clientId, carerId, status, showAll, getTotalItem, searchText, isShowMoreOption },
  ref
) {
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [dayData, setDayData] = useState({});
  const [timesheetList, setTimesheetList] = useState([]);
  const [clientDetailModal, setClientDetailModal] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const [statusReason, setStatusReason] = useState();
  const [timesheetClientDetail, setTimsheetClientDetail] = useState();
  const [selectedParamsReject, setSelectedParamsReject] = useState();
  const [isCancelledApi, setIsCancelledApi] = useState(true);
  const [queryModal, setQueryModal] = useState(false);
  const [selectedDayId, setSelectedDayId] = useState();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  useImperativeHandle(
    ref,
    () => {
      return {
        moveWages() {
          moveToWages(selectedIds);
          setSelectedIds([]);
        },
        cancelTimeSheet() {
          timesheetRejectAndCancelAPI(selectedIds, true);
          setSelectedIds([]);
        },
        rejectTimeSheet() {
          timesheetRejectAndCancelAPI(selectedIds);
          setSelectedIds([]);
        },
        verifyTimeSheet() {
          onVerify(selectedIds);
          setSelectedIds([]);
        },
        approveTimeSheet() {
          onApprove(selectedIds);
          setSelectedIds([]);
        },
      };
    },
    [selectedIds]
  );

  useEffect(() => {
    loadTimesheetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (initScreen) return;
    if (currentPage === 1) {
      loadTimesheetList();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, startDate, endDate, clientId, type, carerId, showAll, searchText]);

  useEffect(() => {
    isShowMoreOption && isShowMoreOption(selectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  useEffect(() => {
    if (!timesheetList) return;
    const updatedDayData = { ...dayData };
    timesheetList?.forEach((week) => {
      const dayLabel = week.id;
      updatedDayData[dayLabel] = {
        date: week.timesheetDate,
        startTime: week?.startTime,
        endTime: week?.endTime,
        breakTime: week?.breakTime ?? null,
        rota: week?.rota,
        sleepoverStart: week?.sleepoverStart,
        sleepoverEnd: week?.sleepoverEnd,
        poNumber: week?.poNumber,
        totalMiles: week?.totalMiles,
        isMileage: week?.totalMiles ? true : false,
        isIncharge: week?.isIncharge ? true : false,
        isSleepOver: week?.sleepoverStart && week?.sleepoverEnd ? true : false,
        totalHour: calculateTotal(
          {
            startTime: week?.startTime,
            endTime: week?.endTime,
            isSleepOver: week?.isSleepOver,
            sleepoverStart: week?.sleepoverStart,
            sleepoverEnd: week?.sleepoverEnd,
          },
          week?.breakTime ?? null
        ),
        attachedFiles: week?.files ?? [],
      };
    });

    setDayData(updatedDayData);
  }, [timesheetList]);

  useEffect(() => {
    timesheetList?.forEach((day) => {
      const startTime = dayData[day.id]?.startTime;
      const endTime = dayData[day.id]?.endTime;
      const breakHourMinutes = dayData[day.id]?.breakHour?.value || null;
      const sleepoverChecked = dayData[day.id]?.isSleepOver;
      const sleepoverStartTime = dayData[day.id]?.sleepoverStart;
      const sleepoverEndTime = dayData[day.id]?.sleepoverEnd;

      if (startTime && endTime) {
        const totalHour = calculateTotal(
          {
            startTime,
            endTime,
            isSleepOver: sleepoverChecked,
            sleepoverStart: sleepoverStartTime,
            sleepoverEnd: sleepoverEndTime,
          },
          breakHourMinutes
        );
        if (totalHour !== dayData[day.id]?.totalHour) {
          setDayData((prevData) => ({
            ...prevData,
            [day.id]: {
              ...prevData[day.id],
              totalHour,
            },
          }));
        }
      }
    });
  }, [dayData]);

  const handleClientDetailModal = (day) => {
    setClientDetailModal(true);
    setTimsheetClientDetail(day);
  };
  const handleReasonModal = (day) => {
    setReasonModal(true);
    setStatusReason(day);
  };

  const onVerify = (dayId) => {
    approveCancelVerify(TimesheetStatusType.verified, dayId);
  };

  const onApprove = (dayId) => {
    approveCancelVerify(TimesheetStatusType.approved, dayId);
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleFileSelect = (file, dayId) => {
    if (bytesToMegaBytes(file?.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = {
        file: file,
        isLoading: true,
        isError: false,
        id: null,
        isOldFile: false,
      };

      let updatedDayData = dayData[dayId] ? { ...dayData[dayId] } : {};
      let updatedFiles = updatedDayData?.attachedFiles ? [...updatedDayData?.attachedFiles] : [];
      updatedFiles.push(fileDetails);
      updatedDayData.attachedFiles = updatedFiles;

      setUploadedFiles(updatedFiles);

      setDayData((prev) => ({
        ...prev,
        [dayId]: updatedDayData,
      }));
      // for API
      uploadFile(fileDetails, dayId, updatedFiles.length - 1); // Pass the day id instead of the index
    }
  };

  const handleFileRemove = (ind, dayId) => {
    setDayData((prevData) => {
      const updatedFiles = prevData[dayId]?.attachedFiles ? [...prevData[dayId]?.attachedFiles] : [];

      if (ind >= 0 && ind < updatedFiles.length) {
        const removedFile = updatedFiles.splice(ind, 1)[0]; // Remove the file at the specified index

        return {
          ...prevData,
          [dayId]: {
            ...prevData[dayId],
            attachedFiles: updatedFiles,
            removeFiles: [...(prevData[dayId]?.removeFiles || []), removedFile],
          },
        };
      }
    });
  };

  const openFileInNewTab = (file) => {
    if (file) {
      window.open(file?.fileUrl, "_blank");
    }
  };

  const handleAttachfileButton = (dayId) => {
    setSelectedDayId(dayId);
    fileInputRef.current.click();
  };
  // ************************ API ******************** //

  const uploadFile = (fileDetails, dayId, index) => {
    fileUploadApi(fileDetails.file, "TIMESHEET", (data, error) => {
      // Create a copy of the files array
      let updatedDayData = dayData[dayId] ? { ...dayData[dayId] } : {};
      let updatedFiles = updatedDayData?.attachedFiles ? [...updatedDayData?.attachedFiles] : [];

      if (error === null) {
        fileDetails.id = data.id;
        fileDetails.isLoading = false;
        fileDetails.fileUrl = URL.createObjectURL(fileDetails.file);

        updatedFiles[index] = fileDetails;
      } else {
        updatedFiles.splice(index, 1);
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }

      updatedDayData.attachedFiles = updatedFiles;
      setDayData((prev) => ({
        ...prev,
        [dayId]: updatedDayData,
      }));
    });
  };

  function loadTimesheetList() {
    scrollToTop();

    if (!type) {
      if (!startDate || !endDate) {
        setInitScreen(false);
        return;
      }
    }
    setInitScreen(true);
    if (searchText) {
      setInitScreen(false);
    } else {
      setInitScreen(true);
    }
    getTimesheetListingAPI(
      {
        pageInfo: {
          pageIndex: currentPage,
          itemsPerPage: PER_PAGE_ITEMS,
        },
        // rotaId: 8,
        startDate: !showAll ? startDate : "",
        endDate: !showAll ? endDate : "",
        clientId: clientId,
        carerId: carerId,
        status:
          status?.value === TimesheetStatusType.reject
            ? "REJECTED"
            : status?.value === "ALL"
            ? undefined
            : status?.value,
        search: searchText,
      },
      (data, error) => {
        if (error === null) {
          setTimesheetList(data?.detail || []);
        }
        if (getTotalItem) {
          getTotalItem(data?.pageInfo?.totalItems);
          setTotalItems(data?.pageInfo?.totalItems);
        }
        setInitScreen(false);
      }
    );
  }

  function approveCancelVerify(action, dayId) {
    let params = {
      rotaCarerDateIds: dayId,
      // timesheetDate: day?.timesheetDate ? moment(day?.timesheetDate).format(SERVER_DATE_FORMAT) : null,
      status: action,
    };
    ConfirmationAlert("Are you sure!", "Do you want to change timesheet to" + " " + toCapitalize(action) + "?", () => {
      moderateStatus(params, (_, error) => {
        if (error === null) {
          showSuccessToast("Timesheet has been moved successfully");
          loadTimesheetList();
        } else {
          showErrorToast(error.message);
        }
      });
    });
  }

  const onFormSubmit = (day, dataRowData) => {
    let timesheetData = {
      rotaId: day?.rota?.id,
      // timesheetId: day?.timesheetId,
      rotaCarerDateId: day?.id,
      fileIds: dataRowData?.attachedFiles
        ? dataRowData?.attachedFiles?.filter((item) => item?.isOldFile === false).map((item) => item?.id)
        : [],
      removeFileIds: dataRowData?.removeFiles?.filter((item) => item?.isOldFile === true).map((item) => item?.id) ?? [],
      date: day?.timesheetDate ? moment(day?.timesheetDate).format("YYYY-MM-DD") : null,
      startTime: dataRowData?.startTime ? moment(dataRowData?.startTime).format("HH:mm") : null,
      endTime: dataRowData?.endTime ? moment(dataRowData?.endTime).format("HH:mm") : null,
      breakMinutes: dataRowData?.breakHour?.value ?? null,
      totalMiles:
        dataRowData?.isMileage && dataRowData?.totalMiles
          ? parseFloat(covertToNumberOnly("" + dataRowData?.totalMiles))
          : null,

      sleepoverStart:
        dataRowData?.isSleepOver && dataRowData?.sleepoverStart
          ? moment(dataRowData?.sleepoverStart).format("HH:mm")
          : null,
      sleepoverEnd:
        dataRowData?.isSleepOver && dataRowData?.sleepoverEnd
          ? moment(dataRowData?.sleepoverEnd).format("HH:mm")
          : null,
      poNumber: dataRowData?.poNumber,
      isIncharge: dataRowData?.isIncharge,
    };
    if (day?.timesheetId) {
      timesheetData.timesheetId = day?.timesheetId;
    }
    setLoading((prevLoading) => ({
      ...prevLoading,
      [day.id]: true,
    }));
    // setInitScreen(true);
    addTimesheetAPI(timesheetData, dataRowData, (_, error) => {
      if (error === null) {
        // let text = "Timesheet" + AppText.SubmittedSuccessfully;
        showSuccessToast("Timesheet has been updated successfully");
        loadTimesheetList();
      } else {
        showErrorToast(error.message);
      }
      setLoading((prevLoading) => ({
        ...prevLoading,
        [day.id]: false,
      }));
      setInitScreen(false);
    });
  };

  function timesheetRejectAndCancelAPI(rotaCarerDateIds, isCancelledApi) {
    // const timesheetDatesArray = day?.map((item) => {
    //   return item?.timesheetDate ? moment(item?.timesheetDate).format(SERVER_DATE_FORMAT) : null;
    // });
    // const rotaCarerDateIdsArray = Array.isArray(day.timesheetDate)
    //   ? day.timesheetDate.map((date) => moment(date).format(SERVER_DATE_FORMAT))
    //   : [moment(day.timesheetDate).format(SERVER_DATE_FORMAT)];
    let params = {
      rotaCarerDateIds: rotaCarerDateIds ?? [],
      // statusReason: "string",
    };
    setQueryModal(true);
    setSelectedParamsReject(params);
    setIsCancelledApi(isCancelledApi);
  }

  const handleQuerySave = (data, cancelledBy, params) => {
    params.statusReason = data;
    params.cancelledBy = cancelledBy; // Add cancelledBy to params
    if (!isCancelledApi) {
      ConfirmationAlert("Are you sure!", "Do you want to reject timesheet" + "?", () => {
        timesheetReject(data, params, (_, error) => {
          if (error === null) {
            showSuccessToast("Timesheet has been rejected successfully");
            loadTimesheetList();
          } else {
            showErrorToast(error.message);
          }
        });
        setQueryModal(false);
      });
    } else {
      ConfirmationAlert("Are you sure!", "Do you want to cancel timesheet" + "?", () => {
        timesheetCancel(params, (_, error) => {
          if (error === null) {
            showSuccessToast("Timesheet has been cancelled successfully");
            loadTimesheetList();
          } else {
            showErrorToast(error.message);
          }
        });
        setQueryModal(false);
      });
    }
  };

  const moveToWages = (Ids) => {
    let params = {
      rotaCarerDateIds: Ids,
    };
    movetoWagesAPI(params, (data, error) => {
      if (error === null) {
        let text = "Timesheet Move to Wages successfully";
        loadTimesheetList();
        showSuccessToast(text);
      } else {
        showErrorToast(error.message);
      }
    });
  };

  const isMobile = useMediaQuery({ maxWidth: 1300 });

  const handleSelectAll = () => {
    let status = timesheetList.some((item) => !selectedIds.includes(item.id));
    if (!status) {
      setSelectedIds([]);
    } else {
      setSelectedIds(timesheetList.map((item) => item.id));
    }
  };

  return (
    <React.Fragment>
      <Head title="Timesheet" />
      <Content>
        {isCheckToList(UserPermissionType.timesheet) && timesheetList?.length > 0 ? (
          <>
            {timesheetList?.length > 0 &&
            timesheetList?.find(
              (item) =>
                item?.status === "APPROVED" ||
                item?.status === "SUBMITTED" ||
                item?.status === "VERIFIED" ||
                item?.status === "PENDING"
            ) ? (
              <div className="mt-1 ms-2 user-select-none">
                <div>
                  <div className="custom-control custom-control-sm custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="selectAll"
                      checked={selectedIds.length === timesheetList.length}
                      onChange={() => handleSelectAll()}
                    />{" "}
                    <label className="custom-control-label fs-14" htmlFor="selectAll">
                      Select All
                    </label>
                  </div>
                </div>
              </div>
            ) : null}
            <Block>
              {timesheetList?.map((day, index) => {
                let dataRowData = dayData[day.id] ?? {};
                let files = dataRowData?.attachedFiles ?? [];
                const isDateGreaterThanToday = moment(day?.timesheetDate).isAfter(moment(), "day");
                const isSelected = selectedItems.includes(day);
                return (
                  <React.Fragment key={index}>
                    <PreviewAltCard bodyClass={"pt-0 pb-0 px-3"} className={"mt-2"}>
                      <>
                        <Row className="timesheet-row">
                          <Col key={index}>
                            <div className="timesheet-data-header-box-size">
                              {day?.status === TimesheetStatusType.approved ||
                              day?.status === TimesheetStatusType.submitted ||
                              day?.status === TimesheetStatusType.verified ||
                              day?.status === TimesheetStatusType.pending ? (
                                <div className="me-1 align-center">
                                  <div className="custom-control custom-control-sm custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`onRow ${day?.id}`}
                                      checked={selectedIds.includes(day.id)}
                                      onChange={(e) => {
                                        if (selectedIds.includes(day.id)) {
                                          setSelectedIds(selectedIds.filter((val) => val !== day.id));
                                        } else {
                                          setSelectedIds([...selectedIds, day.id]);
                                        }
                                      }}
                                    />
                                    <label className="custom-control-label" htmlFor={`onRow ${day?.id}`}></label>
                                  </div>
                                </div>
                              ) : null}

                              <span className="every-timesheet-date-header me-3">
                                {toCapitalize(moment(day?.rota?.fromDate).format("dddd"))} -{" "}
                                {moment(day?.rota?.fromDate).format("DD/MM/YYYY")}
                              </span>
                              <span className="me-3">|</span>
                              <span className="every-timesheet-date-header me-3">
                                {moment(day?.rota?.startTime).format(TIME_FORMAT_GLOBAL)} -{" "}
                                {moment(day?.rota?.endTime).format(TIME_FORMAT_GLOBAL)}
                              </span>
                              <span className="me-3">|</span>
                              <span className="every-timesheet-date-header me-3">{day?.grade?.name ?? "N/A"}</span>
                              <span className="me-3">|</span>
                              <span className="every-timesheet-date-header">{day?.refId ? day?.refId : "N/A"}</span>
                              {day?.status === TimesheetStatusType.cancelled || day?.status === "REJECTED" ? (
                                <span
                                  className="ms-auto cursor-pointer align-center"
                                  onClick={() => handleReasonModal(day)}
                                >
                                  {/* <Icon name={"info"} className={"text-white fs-5"} /> */}
                                  <span>View reason</span>
                                </span>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row className="timesheet-row-text">
                          {!type || type === TimeSheetType.hospital ? (
                            <Col className="fs-14" xxl={type ? 2 : 1} lg={2} md={2} sm={2}>
                              <span className="">Carer</span>
                            </Col>
                          ) : null}

                          {!type || type === TimeSheetType.carer ? (
                            <Col className="fs-14" xxl={2} lg={2} md={2} sm={2}>
                              <span className="">Client</span>
                            </Col>
                          ) : null}

                          <Col className="fs-14" xxl={3} lg={3} md={3} sm={3}>
                            <span className="">{isMobile || type ? "Time" : "Actual Time"}</span>
                          </Col>
                          <Col className="fs-14" xxl={1} lg={1} md={2} sm={2}>
                            <span className={`${isMobile || type ? "mobile-title" : ""}`}>
                              <label className="">{isMobile || type ? "T. Hour" : "Total Hour"}</label>
                            </span>
                          </Col>
                          <Col className="fs-14" xxl={1} lg={1} md={2} sm={1}>
                            <span className="">{isMobile || type ? "Po" : "Po Number"}</span>
                          </Col>
                          <Col
                            className="fs-14"
                            xxl={type ? 3 : 2}
                            lg={type ? 4 : 2}
                            md={type ? 4 : 2}
                            sm={type ? 4 : 2}
                          ></Col>
                        </Row>
                        <Row>
                          {!type || type === TimeSheetType.hospital ? (
                            <Col className="timesheet-col-text" xxl={type ? 2 : 1} lg={2} md={2} sm={4}>
                              <span className="">{day?.carer?.firstName + " " + day?.carer?.lastName}</span>
                            </Col>
                          ) : null}
                          {!type || type === TimeSheetType.carer ? (
                            <Col className="timesheet-col-text" xxl={2} lg={2} md={2} sm={4}>
                              <span className="">{day?.client?.name}</span>
                            </Col>
                          ) : null}
                          <Col className="timesheet-col-text" xxl={3} lg={3} md={3} sm={4}>
                            <div className="d-flex">
                              <span className={`me-3 w-75 ${isMobile || type ? "mobile-title" : ""}`}>
                                <FormTimePicker
                                  placeholderText={"Start Time"}
                                  removeIcon
                                  disabled={
                                    isDateGreaterThanToday ||
                                    day?.status === TimesheetStatusType.cancelled ||
                                    day?.status === TimesheetStatusType.finalised
                                  }
                                  selected={dayData[day.id]?.startTime}
                                  onChange={(startTime) => {
                                    setDayData((prevData) => ({
                                      ...prevData,
                                      [day.id]: {
                                        ...prevData[day.id],
                                        startTime: startTime,
                                        date: moment(day?.timesheetDate).toDate(),
                                      },
                                    }));
                                  }}
                                />
                              </span>
                              <span className="me-3 w-75">
                                <FormTimePicker
                                  placeholderText={"End Time"}
                                  removeIcon
                                  disabled={
                                    isDateGreaterThanToday ||
                                    day?.status === TimesheetStatusType.cancelled ||
                                    day?.status === TimesheetStatusType.finalised
                                  }
                                  selected={dayData[day.id]?.endTime}
                                  onChange={(endTime) => {
                                    setDayData((prevData) => ({
                                      ...prevData,
                                      [day.id]: {
                                        ...prevData[day.id],
                                        endTime: endTime,
                                        date: moment(day?.rotaDate).toDate(),
                                      },
                                    }));
                                  }}
                                />
                              </span>
                              <span className=" w-75 ">
                                <BreakHourDropdown
                                  className={isDateGreaterThanToday ? "cursor-none" : ""}
                                  value={dayData[day.id]?.breakHour ?? null}
                                  selected={dayData[day.id]?.breakTime ?? null}
                                  intervalMinutes={5}
                                  disabled={
                                    isDateGreaterThanToday ||
                                    day?.status === TimesheetStatusType.cancelled ||
                                    day?.status === TimesheetStatusType.finalised
                                  }
                                  onSelect={(selectedOption) => {
                                    if (selectedOption) {
                                      const updatedDayData = {
                                        ...dayData,
                                        [day?.id]: {
                                          ...dayData[day.id],
                                          breakHour: selectedOption,
                                          date: moment(day?.timesheetDate).toDate(),
                                          totalHour: calculateTotal(
                                            {
                                              startTime: dayData[day.id]?.startTime,
                                              endTime: dayData[day.id]?.endTime,
                                              isSleepOver: dayData[day.id]?.isSleepOver,
                                              sleepoverStart: dayData[day.id]?.sleepoverStart,
                                              sleepoverEnd: dayData[day.id]?.sleepoverEnd,
                                            },
                                            selectedOption?.value
                                          ),
                                        },
                                      };
                                      setDayData(updatedDayData);
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          </Col>
                          <Col className="timesheet-col-text" xxl={1} lg={1} md={2} sm={4}>
                            <span className="total-hour-box text-white">{dayData[day.id]?.totalHour ?? ""}</span>
                          </Col>
                          <Col className="timesheet-col-text" xxl={2} lg={2} md={2} sm={4}>
                            <FormInput
                              baseClass={"w-60"}
                              placeholder={"Po Number"}
                              maxLength={50}
                              isShowLabel={false}
                              disabled={
                                isDateGreaterThanToday ||
                                day?.status === TimesheetStatusType.cancelled ||
                                day?.status === TimesheetStatusType.finalised
                              }
                              removeCurrency={true}
                              type={FormInputType.default}
                              value={dayData[day.id]?.poNumber}
                              onTextChange={(poNumber) => {
                                // const numericValue = poNumber.replace(/\D/g, ""); // Remove non-numeric characters
                                setDayData((prevData) => ({
                                  ...prevData,
                                  [day.id]: {
                                    ...prevData[day.id],
                                    poNumber: poNumber,
                                  },
                                }));
                              }}
                            />
                          </Col>
                          <Col
                            xxl={type ? 4 : 3}
                            lg={type ? 4 : 2}
                            md={type ? 4 : 2}
                            sm={4}
                            className="d-flex justify-content-end"
                          >
                            <div className="d-flex">
                              {!isDateGreaterThanToday && day?.status !== TimesheetStatusType.cancelled && (
                                <ActionButton
                                  className="cursor-pointer w-25"
                                  onClick={() => handleClientDetailModal(day)}
                                  title={
                                    <>
                                      <Icon name={"eye"}></Icon>
                                    </>
                                  }
                                />
                              )}
                              {day?.status !== TimesheetStatusType.cancelled &&
                                day?.status !== TimesheetStatusType.finalised &&
                                day?.status !== TimesheetStatusType.approved &&
                                isAllowToAccess(UserPermissionType.timesheet, UserActionType.save) && (
                                  <ActionButton
                                    title="Save"
                                    className={"timesheet-save-button ms-1"}
                                    type="button"
                                    onClick={() => onFormSubmit(day, dataRowData)}
                                    loading={loading[day.id]}
                                    // disabled={!isFormValid()}
                                  />
                                )}
                            </div>
                            {day?.status !== TimesheetStatusType.cancelled &&
                              day?.status !== TimesheetStatusType.finalised && (
                                <span className="">
                                  <MoreOptions
                                    options={[
                                      {
                                        icon: "file-remove-fill",
                                        text: "Cancel",
                                        action: () => {
                                          timesheetRejectAndCancelAPI([day?.id], true);
                                        },
                                        hide:
                                          day?.status === TimesheetStatusType.approved ||
                                          !isAllowToAccess(UserPermissionType.timesheet, UserActionType.cancel),
                                      },
                                      {
                                        icon: "file-remove-fill",
                                        text: "In Query",
                                        action: () => {
                                          timesheetRejectAndCancelAPI([day?.id]);
                                        },
                                        hide:
                                          day?.status !== TimesheetStatusType.verified ||
                                          !isAllowToAccess(UserPermissionType.timesheet, UserActionType.inquery),
                                      },
                                      {
                                        icon: "file-check-fill",
                                        text: "Verify",
                                        action: () => {
                                          onVerify([day?.id]);
                                        },
                                        hide:
                                          (day?.status !== TimesheetStatusType.submitted &&
                                            day?.status !== TimesheetStatusType.reject) ||
                                          !isAllowToAccess(UserPermissionType.timesheet, UserActionType.verify),
                                      },
                                      {
                                        icon: "list-check",
                                        text: "Approve",
                                        action: () => {
                                          onApprove([day?.id]);
                                        },
                                        hide:
                                          day?.status !== TimesheetStatusType.verified ||
                                          !isAllowToAccess(UserPermissionType.timesheet, UserActionType.approve),
                                      },
                                      {
                                        icon: "file-text",
                                        text: "Move To Wages",
                                        action: () => {
                                          moveToWages([day?.id]);
                                        },
                                        hide:
                                          day?.status !== TimesheetStatusType.approved ||
                                          !isAllowToAccess(UserPermissionType.timesheet, UserActionType.moveToWages),
                                      },
                                    ]}
                                  />
                                </span>
                              )}
                          </Col>
                          <div className="timesheet-border-card-end"></div>
                        </Row>
                        <Row className="">
                          <Col xxl={type ? 3 : 2} lg={type ? 3 : 2} md={6} className="py-2 col-xl-3">
                            <UncontrolledDropdown>
                              <div className="btn-group">
                                {files.length > 0 && (
                                  <>
                                    <div className="timesheet-attach-button">
                                      <DropdownToggle className="dropdown-toggle-split timesheet-attach-button">
                                        <Icon name="clip"></Icon>
                                        <span>{`${`${files?.length}`}`}</span>
                                      </DropdownToggle>
                                    </div>
                                  </>
                                )}
                                <ActionButton
                                  htmlFor="files"
                                  disabled={
                                    isDateGreaterThanToday ||
                                    day?.status === TimesheetStatusType.cancelled ||
                                    day?.status === TimesheetStatusType.finalised
                                  }
                                  className="cursor-pointer"
                                  title={"Add Files"}
                                  onClick={() => handleAttachfileButton(day.id)}
                                />
                              </div>
                              <DropdownMenu>
                                <ul className="link-list-opt">
                                  {files?.length > 0 &&
                                    files?.map((file, index) => (
                                      <li key={index}>
                                        <DropdownItem
                                          tag="a"
                                          href="#links"
                                          onClick={(ev) => ev.preventDefault()}
                                          className="d-flex justify-content-between"
                                        >
                                          <span onClick={() => openFileInNewTab(file)}>
                                            {file?.name
                                              ? file?.name?.length > 20
                                                ? `${file?.name?.substring(0, 20)}...`
                                                : file?.name
                                              : file?.file?.name}
                                          </span>
                                          {day?.status !== TimesheetStatusType.cancelled &&
                                          day?.status !== TimesheetStatusType.approved &&
                                          day?.status !== TimesheetStatusType.finalised ? (
                                            <span onClick={() => handleFileRemove(index, day.id)}>
                                              <Icon name="trash"></Icon>
                                            </span>
                                          ) : null}
                                        </DropdownItem>
                                      </li>
                                    ))}
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </Col>
                          <Col
                            xxl={type ? 2 : 1}
                            lg={2}
                            md={6}
                            className={"py-1 align-center user-select-none cursor-pointer col-xl-2"}
                          >
                            <FormCheckbox
                              label={"Incharge?"}
                              id={`incharge ${day.id}`}
                              name={`incharge ${day.id}`}
                              checked={dayData[day.id]?.isIncharge}
                              disabled={
                                isDateGreaterThanToday ||
                                day?.status === TimesheetStatusType.cancelled ||
                                day?.status === TimesheetStatusType.finalised
                              }
                              onChange={() => {
                                setDayData((prevData) => ({
                                  ...prevData,
                                  [day.id]: {
                                    ...prevData[day.id],
                                    isIncharge: !dayData[day.id]?.isIncharge,
                                  },
                                }));
                              }}
                            />
                          </Col>
                          <Col xxl={type ? 3 : 2} lg={3} md={6} className={"py-1 align-center col-xl-3"}>
                            <div className="d-flex align-center justify-content-between user-select-none cursor-pointer">
                              <FormCheckbox
                                label={"Mileage"}
                                name={`mileage ${day.id}`}
                                id={`mileage ${day.id}`}
                                checked={dayData[day.id]?.isMileage}
                                disabled={
                                  isDateGreaterThanToday ||
                                  day?.status === TimesheetStatusType.cancelled ||
                                  day?.status === TimesheetStatusType.finalised
                                }
                                onChange={() => {
                                  setDayData((prevData) => ({
                                    ...prevData,
                                    [day.id]: {
                                      ...prevData[day.id],
                                      isMileage: !dayData[day.id]?.isMileage,
                                    },
                                  }));
                                }}
                              />
                              {dayData[day.id]?.isMileage && (
                                <FormInput
                                  baseClass={"w-60"}
                                  placeholder={"Enter Miles"}
                                  isShowLabel={false}
                                  disabled={
                                    isDateGreaterThanToday ||
                                    day?.status === TimesheetStatusType.cancelled ||
                                    day?.status === TimesheetStatusType.finalised
                                  }
                                  removeCurrency={true}
                                  // maxLength={3}
                                  type={FormInputType.amount}
                                  value={dayData[day.id]?.totalMiles}
                                  onTextChange={(mile) => {
                                    setDayData((prevData) => ({
                                      ...prevData,
                                      [day.id]: {
                                        ...prevData[day.id],
                                        totalMiles: mile,
                                      },
                                    }));
                                  }}
                                />
                              )}
                            </div>
                          </Col>
                          <Col xxl={type ? 4 : 3} lg={4} md={6} className={"py-1 align-center col-xl-4"}>
                            <div className="d-flex align-center justify-content-between user-select-none cursor-pointer">
                              <FormCheckbox
                                // label={type && isResponsiveScreen ? "S.O." : "Sleep Over"}
                                label={type || isMobile ? "S.O." : "Sleep Over"}
                                name={`sleepover ${day.id}`}
                                id={`sleepover ${day.id}`}
                                checked={dayData[day.id]?.isSleepOver}
                                disabled={
                                  isDateGreaterThanToday ||
                                  day?.status === TimesheetStatusType.cancelled ||
                                  day?.status === TimesheetStatusType.finalised
                                }
                                onChange={() => {
                                  const updatedDayData = {
                                    ...dayData,
                                    [day.id]: {
                                      ...dayData[day.id],
                                      isSleepOver: !dayData[day.id]?.isSleepOver,
                                      totalHour: calculateTotal(
                                        {
                                          startTime: dayData[day.id]?.startTime,
                                          endTime: dayData[day.id]?.endTime,
                                          isSleepOver: !dayData[day.id]?.isSleepOver,
                                          sleepoverStart: dayData[day.id]?.sleepoverStart,
                                          sleepoverEnd: dayData[day.id]?.sleepoverEnd,
                                        },
                                        dayData[day.id]?.breakHour?.value || null
                                      ),
                                    },
                                  };
                                  setDayData(updatedDayData);
                                }}
                              />
                              <div className="d-flex justify-content-end ms-3">
                                <span className="me-3 w-100px">
                                  {dayData[day.id]?.isSleepOver && (
                                    <FormTimePicker
                                      removeIcon
                                      placeholderText={"Start Time"}
                                      disabled={
                                        isDateGreaterThanToday ||
                                        day?.status === TimesheetStatusType.cancelled ||
                                        day?.status === TimesheetStatusType.finalised
                                      }
                                      selected={dayData[day.id]?.sleepoverStart}
                                      onChange={(sleepoverStart) => {
                                        const updatedDayData = {
                                          ...dayData,
                                          [day.id]: {
                                            ...dayData[day.id],
                                            sleepoverStart,
                                            totalHour: calculateTotal(
                                              {
                                                startTime: dayData[day.id]?.startTime,
                                                endTime: dayData[day.id]?.endTime,
                                                isSleepOver: dayData[day.id]?.isSleepOver,
                                                sleepoverStart,
                                                sleepoverEnd: dayData[day.id]?.sleepoverEnd,
                                              },
                                              dayData[day.id]?.breakHour?.value || null
                                            ),
                                          },
                                        };
                                        setDayData(updatedDayData);
                                      }}
                                    />
                                  )}
                                </span>
                                <span className="w-100px me-3">
                                  {dayData[day.id]?.isSleepOver && (
                                    <FormTimePicker
                                      removeIcon
                                      placeholderText={"End Time"}
                                      disabled={
                                        isDateGreaterThanToday ||
                                        day?.status === TimesheetStatusType.cancelled ||
                                        day?.status === TimesheetStatusType.finalised
                                      }
                                      selected={dayData[day.id]?.sleepoverEnd}
                                      onChange={(sleepoverEnd) => {
                                        const updatedDayData = {
                                          ...dayData,
                                          [day.id]: {
                                            ...dayData[day.id],
                                            sleepoverEnd,
                                            totalHour: calculateTotal(
                                              {
                                                startTime: dayData[day.id]?.startTime,
                                                endTime: dayData[day.id]?.endTime,
                                                isSleepOver: dayData[day.id]?.isSleepOver,
                                                sleepoverStart: dayData[day.id]?.sleepoverStart,
                                                sleepoverEnd,
                                              },
                                              dayData[day.id]?.breakHour?.value || null
                                            ),
                                          },
                                        };
                                        setDayData(updatedDayData);
                                      }}
                                    />
                                  )}
                                </span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    </PreviewAltCard>
                  </React.Fragment>
                );
              })}
            </Block>
            <div className="mt-2">
              <PaginationComponent
                itemPerPage={PER_PAGE_ITEMS}
                totalItems={totalItems}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </>
        ) : (
          <>
            {!isCheckToList(UserPermissionType.timesheet) ? (
              <Block className={"pt-1"}>
                <PreviewAltCard>
                  <span className="d-flex justify-content-center">You do not have permission</span>
                </PreviewAltCard>
              </Block>
            ) : (
              <>
                <Block className={"pt-1"}>
                  <PreviewAltCard>
                    <span className="d-flex justify-content-center">There are no records found</span>
                  </PreviewAltCard>
                </Block>
              </>
            )}
          </>
        )}
      </Content>
      <LoadingComponent isLoading={initScreen} />
      <ClientDetailModal
        // isCancelReason={isCancelledApi}
        item={timesheetClientDetail}
        visible={clientDetailModal}
        // selectedDate={selectedParamsReject?.timesheetDates.join(", ")}
        onClosed={() => {
          setClientDetailModal(false);
        }}
        // onSaved={(data) => handleQuerySave(data, selectedParamsReject)}
      />
      <QueryModal
        isCancelReason={isCancelledApi}
        visible={queryModal}
        selectedDate={selectedParamsReject?.timesheetDate}
        onClosed={() => {
          setQueryModal(false);
        }}
        onSaved={(data, cancelledBy) => handleQuerySave(data, cancelledBy, selectedParamsReject)}
      />
      <QueryReasonModal
        visible={reasonModal}
        selectedDate={selectedParamsReject?.timesheetDate}
        onClosed={() => {
          setReasonModal(false);
        }}
        statusData={statusReason}
      />

      <div className="form-group mb-n4">
        <input
          ref={fileInputRef}
          type="file"
          name={`files-attached`}
          accept=".pdf,.jpeg,.png,.jpg,.WebP"
          // accept=".pdf,.doc,.docx,.xlsx,.xls,.jpeg,.png,.jpg,.WebP"
          className="form-control"
          id={`files-attached`}
          // multiple
          style={{ display: "none" }}
          onChange={(e) => {
            let temp = Array.prototype.slice.call(e.target.files);
            if (temp.length > 0 && selectedDayId) {
              handleFileSelect(temp[0], selectedDayId);
            }
            setSelectedDayId();
            e.target.value = "";
          }}
        />
      </div>
    </React.Fragment>
  );
  // };
});

export default Timesheets;
