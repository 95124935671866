/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  LoadingComponent,
  PreviewAltCard,
  Row,
} from "../../../components/Component";
import { rotaDayListAPI } from "./RotaTableData";
import { RotaOptions } from "../../../utils";
import { convertHexToColor } from "../../../utils/Functions";
import moment from "moment";
import RotaDetailModal, { ModalStatus } from "./RotaDetailModal";
import { GradeBadge } from "../../../components/badge/GradeBadge";

export const RotaType = {
  hospital: "hospital",
  carer: "carer",
};

const RotaListing = ({
  carerId,
  clientId,
  type,
  startDate,
  endDate,
  showAll,
  gradeId,
  shiftType,
  agencyId,
  status,
}) => {
  const [rotaList, setRotaList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [selectedData, setSelectedData] = useState();

  function handleShowDetail(detail) {
    setDetailModal(true);
    setSelectedData(detail);
  }

  useEffect(() => {
    if (startDate && endDate) {
      loadRotaAPI();
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      if (startDate && endDate) {
        loadRotaAPI();
      }
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, startDate, endDate, carerId, showAll, clientId, type, gradeId, shiftType, agencyId]);

  function loadRotaAPI() {
    if (loading) return;

    let params = {
      startDate: !showAll ? startDate : "",
      endDate: !showAll ? endDate : "",
      gradeId: gradeId ? gradeId : null,
      shiftType: shiftType?.value === "ANY" ? "" : shiftType ? shiftType.value : null,
      clientId: clientId,
      carerId: carerId,
      agencyId: agencyId,
      status: status === RotaOptions.open ? "ACTIVE" : status,
    };

    // setLoading(true);
    rotaDayListAPI(params, (data, error) => {
      if (error === null) {
        if (currentPage === 1) {
          setRotaList(data.list);
        }
      }
      // setLoading(false);
    });
  }

  const groupedRotas = [];
  rotaList.forEach((rota) => {
    const rotaDate = moment(rota?.rotaDate).format("DD-MM-YYYY");
    const rotaDay = moment(rota?.rotaDate).format("dddd");
    const existingGroup = groupedRotas.find((group) => group.date === rotaDate);

    if (existingGroup) {
      existingGroup.rotas.push(rota);
    } else {
      groupedRotas.push({ date: rotaDate, day: rotaDay, rotas: [rota] });
    }
  });

  const sortedRotas = groupedRotas.sort((a, b) => moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"));

  return (
    <>
      <PreviewAltCard className="h-100 w-100 overflow-auto">
        <DataTable className={"mb-2"}>
          <DataTableBody>
            <DataTableHead>
              <DataTableRow className="border-bottom-0 rota-head-row">
                <Row>
                  <Col size={status === RotaOptions.cancelled ? 2 : 3}>
                    <span>Carer</span>
                  </Col>
                  <Col size={status === RotaOptions.cancelled ? 2 : 3}>
                    <span>Client</span>
                  </Col>
                  <Col size={status === RotaOptions.cancelled ? 2 : 3}>
                    <span>Grade</span>
                  </Col>
                  <Col size={status === RotaOptions.cancelled ? 2 : 3}>
                    <span className="ms-4">Start Time -End Time</span>
                  </Col>
                  {status === RotaOptions.cancelled && (
                    <>
                      <Col size="2">
                        <span className="ms-4">Cancelled By</span>
                      </Col>
                      <Col size="2">
                        <span className="ms-4">Cancelled Date</span>
                      </Col>
                    </>
                  )}
                </Row>
              </DataTableRow>
            </DataTableHead>
          </DataTableBody>
        </DataTable>
        {sortedRotas.length > 0 ? (
          sortedRotas.map((group, groupIndex) => (
            <React.Fragment key={groupIndex}>
              <DataTable className="mb-2">
                <DataTableBody>
                  <DataTableHead>
                    <DataTableRow className=" fw-bold border-bottom text-black bg-light">
                      <span>
                        {group?.day} / {group?.date}
                      </span>
                    </DataTableRow>
                  </DataTableHead>
                  {group?.rotas?.map((rota, index) => (
                    <DataTableItem key={index} className="">
                      <DataTableRow>
                        <Row onClick={() => handleShowDetail(rota)} className="cursor-pointer">
                          <Col size={status === RotaOptions.cancelled ? 2 : 3} className={"text-black"}>
                            <span>{rota?.carer}</span>
                          </Col>
                          <Col size={status === RotaOptions.cancelled ? 2 : 3} className={"text-black"}>
                            <span>{rota?.client?.name}</span>
                          </Col>
                          <Col size={status === RotaOptions.cancelled ? 2 : 3}>
                            <GradeBadge
                              label={rota?.grade?.name}
                              className={"me-1 my-1"}
                              color={`outline-${convertHexToColor(rota?.grade?.colourHex)}`}
                            />
                          </Col>
                          <Col size={status === RotaOptions.cancelled ? 2 : 3}>
                            <span className="ms-4">
                              {" "}
                              {moment(rota?.startTime).format("HH:mm")} - {moment(rota?.endTime).format("HH:mm")}
                            </span>
                          </Col>
                          {status === RotaOptions.cancelled && (
                            <>
                              <Col size={2}>
                                <span className="ms-4"> {rota?.lastModifiedBy}</span>
                              </Col>
                              <Col size={2}>
                                <span className="ms-4"> {moment(rota?.lastModifiedDate).format("DD-MM-YYYY")}</span>
                              </Col>
                            </>
                          )}
                        </Row>
                      </DataTableRow>
                    </DataTableItem>
                  ))}
                </DataTableBody>
              </DataTable>
            </React.Fragment>
          ))
        ) : (
          <div className=" text-center">There is no data found </div>
        )}
      </PreviewAltCard>
      <RotaDetailModal
        visible={detailModal}
        rotaStatus={status}
        item={selectedData}
        onClosed={(status) => {
          if (status === ModalStatus.success) {
            loadRotaAPI();
          }
          setDetailModal(false);
        }}
      />
      <LoadingComponent isLoading={loading} />
    </>
  );
};

export default RotaListing;
