/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  AgencyDropdown,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  FormInput,
  Icon,
  PreviewAltCard,
  PreviewCard,
  RotaStatusDropDown,
  Row,
  ShiftDropDown,
} from "../../../components/Component";
import RotaListing from "./RotaCalendar";
import { ButtonGroup } from "reactstrap";
import RotaList, { RotaType } from "./RotaList";
import { exportRotaAPI, showCarerListAPI, showClientListAPI } from "./RotaTableData";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GradeTypeDropDownWithoutCarer } from "../../../components/dropdowns/GradeTypeDropDown";
import { ClientCarerBadge } from "../../../components/badge/GradeBadge";
import { PER_PAGE_ITEMS, RotaOptions, showErrorToast } from "../../../utils";
import { ImportExportButton } from "../../../components/button/ActionButton";
import DateRangePicker from "../../../components/input/date-time/DateRangePicker";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../services/permission/permission";

export const RotaCopyJob = {
  isRota: "ROTA",
  isCopy: "COPY",
  isJob: "JOB",
};

const Rotas = () => {
  const history = useHistory();
  const [status, setStatus] = useState({ id: 1, label: "Open", value: "OPEN" });
  const [grade, setGrade] = useState();
  const [shiftType, setShiftType] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showList, setShowList] = useState(true);
  const [showCarerList, setShowCarerList] = useState(false);
  const [carerClientList, setCarerClientList] = useState([]);
  const [selectedClientCarer, setSelectedClientCarer] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [matrixPageInfo, setMatrixPageInfo] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [sm, updateSm] = useState(false);
  const [isSwitchOn, setSwitchOn] = useState(false); // State for switch
  const [agency, setAgency] = useState({ id: 0, label: "All", value: "" });

  useEffect(() => {
    setCarerClientList([]); // Clear the list when switching tabs
    if (showCarerList) {
      callCarerListApi();
    } else {
      callClientListApi();
    }
  }, [showCarerList, searchText, currentPage]);

  useEffect(() => {
    if (!isSwitchOn && carerClientList.length > 0) {
      const initialItem = carerClientList.find((item) => item.id === selectedClientCarer?.id) || carerClientList[0];
      handleSelectCarer(initialItem);
    } else if (isSwitchOn) {
      handleSelectCarer(null);
    }
  }, [carerClientList, isSwitchOn]);

  useEffect(() => {
    const scrollableDiv = document.getElementById("scroll-matrix");
    scrollableDiv?.addEventListener("scroll", handleScroll, true);
    return () => {
      scrollableDiv?.removeEventListener("scroll", handleScroll, true);
    };
  }, [currentPage, isLoading]);

  const onExportClick = () => {
    if (!startDate && !endDate) return;
    setLoading(true);
    let params = {
      startDate: startDate,
      endDate: endDate,
      shiftType: shiftType?.value === "ANY" ? "" : shiftType?.value || null,
      status: status?.value === "OPEN" ? "ACTIVE" : status?.value,
      agencyId: agency?.id ?? null,
    };
    if (!showCarerList) {
      params.clientId = selectedClientCarer?.id;
    } else {
      params.carerId = selectedClientCarer?.id;
    }

    if (grade) {
      params.gradeId = grade.id;
    }
    exportRotaAPI(params, (data, error) => {
      if (error === null) {
        window.open(data.fileUrl, "default");
      }
      setLoading(false);
    });
  };

  const toggleCalendar = () => {
    setShowCalendar(true);
    setShowList(false);
  };

  const toggleList = () => {
    setShowList(true);
    setShowCalendar(false);
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading && currentPage !== matrixPageInfo?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const callCarerListApi = () => {
    setIsLoading(true);
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: PER_PAGE_ITEMS,
      },
      status: "ACTIVE",
      search: searchText || null,
    };

    showCarerListAPI(params, (data, error) => {
      if (error === null) {
        setMatrixPageInfo(data?.pageInfo);
        if (currentPage === 1) {
          setCarerClientList(data?.list);
        } else {
          setCarerClientList((prevList) => [...prevList, ...data?.list]);
        }
      } else {
        showErrorToast(error.message);
      }
      setIsLoading(false);
    });
  };

  const callClientListApi = () => {
    setIsLoading(true);
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: PER_PAGE_ITEMS,
      },
      status: "ACTIVE",
      search: searchText || null,
    };

    showClientListAPI(params, (data, error) => {
      if (error === null) {
        setMatrixPageInfo(data?.pageInfo);
        if (currentPage === 1) {
          setCarerClientList(data?.list);
        } else {
          setCarerClientList((prevList) => [...prevList, ...data?.list]);
        }
      } else {
        showErrorToast(error.message);
      }
      setIsLoading(false);
    });
  };

  const handleSelectCarer = (item) => {
    if (isSwitchOn && item) {
      setSwitchOn(false);
    }
    setSelectedClientCarer(item);
  };

  const handleSwitchToggle = () => {
    setSwitchOn(!isSwitchOn);
  };

  const handleDatePickerChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onAddClick = () => {
    history.push(`${process.env.PUBLIC_URL}/add-job`, { type: RotaCopyJob.isRota });
  };

  const toggleCarerList = () => {
    setShowCarerList(true);
    setCurrentPage(1);
  };

  const toggleClientList = () => {
    setShowCarerList(false);
    setCurrentPage(1);
  };

  return (
    <React.Fragment>
      <Head title="Rota" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="align-items-start">
            <BlockHeadContent className="w-320px">
              <ul className="d-flex justify-content-between">
                <li>
                  <BlockTitle page className="">
                    Rota
                  </BlockTitle>
                </li>
                <li>
                  <ButtonGroup className="">
                    <Button
                      className={`btn-dim btn-white ${showList ? "bg-purple btn-lighter" : ""}`}
                      onClick={toggleList}
                    >
                      <Icon name="view-list-fill" />
                    </Button>
                    <Button
                      className={`btn-dim btn-white ${!showList ? "bg-purple btn-lighter" : ""}`}
                      onClick={toggleCalendar}
                    >
                      <Icon name="calendar-alt" />
                    </Button>
                  </ButtonGroup>
                </li>
              </ul>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-2 d-flex flex-wrap justify-content-end">
                    <li>
                      <GradeTypeDropDownWithoutCarer
                        value={grade}
                        isClearable
                        isShowAll
                        className="w-225px"
                        placeholder="Grade Type"
                        onSelect={(selectedGrade) => setGrade(selectedGrade)}
                      />
                    </li>
                    <li>
                      <RotaStatusDropDown value={status} className="w-125px" onSelect={(e) => setStatus(e)} />
                    </li>
                    <li className="">
                      <AgencyDropdown
                        isShowAll={true}
                        className="w-125px"
                        value={agency}
                        onSelect={(e) => {
                          setAgency(e);
                        }}
                      />
                    </li>
                    <li>
                      <ShiftDropDown
                        className="w-120px"
                        isAny={true}
                        value={shiftType}
                        onSelect={(selectedShift) => setShiftType(selectedShift)}
                      />
                    </li>
                    <li>
                      <DateRangePicker onDateChange={handleDatePickerChange} />
                    </li>
                    {isAllowToAccess(UserPermissionType.rota, UserActionType.export) && (
                      <li>
                        <ImportExportButton
                          title="Export"
                          onClick={onExportClick}
                          iconName="arrow-up"
                          loading={loading}
                        />
                      </li>
                    )}
                    {isAllowToAccess(UserPermissionType.rota, UserActionType.add) && (
                      <li>
                        <ImportExportButton addButton title="Add" onClick={onAddClick} iconName="plus" />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          {isCheckToList(UserPermissionType.rota) ? (
            <PreviewCard className="height-matrix-card rota-inner-card">
              <div className="d-flex h-100">
                <div className="filter-div">
                  <Row className="row-carer-filter">
                    <Col size="12" className="carer-filter-height">
                      <div className="mb-2 align-center">
                        Filter
                        <label className="switch ms-2 me-2">
                          <input type="checkbox" checked={isSwitchOn} onChange={handleSwitchToggle} />
                          <span className="slider-rotas round"></span>
                        </label>
                        ALL
                      </div>
                      <FormInput
                        baseClass="pe-3"
                        labelGroupClass="m-0"
                        disabled={isSwitchOn}
                        value={searchText || ""}
                        placeholder={!showCarerList ? "Search Client" : "Search Carer"}
                        onTextChange={(text) => setSearchText(text)}
                      />

                      <ButtonGroup className="buttongrp-carer pe-3">
                        <Button
                          className={`btn-dim bg-light text-center d-flex justify-content-center ${
                            !showCarerList ? "bg-purple text-white btn-lighter" : ""
                          }`}
                          onClick={toggleClientList}
                        >
                          Client
                        </Button>
                        <Button
                          className={`btn-dim bg-light text-center d-flex justify-content-center ${
                            showCarerList ? "bg-purple text-white btn-lighter" : ""
                          }`}
                          onClick={toggleCarerList}
                        >
                          Carer
                        </Button>
                      </ButtonGroup>
                      <div className="carer_client_filter" id="scroll-matrix">
                        {carerClientList?.map((item, id) => (
                          <Col size="12" key={id}>
                            <ClientCarerBadge
                              carerImage={item.carerImage?.imageUrl}
                              isCarer={showCarerList}
                              className="mt-2"
                              label={item.name}
                              phoneNumber={`+${item.phoneNumber}`}
                              color={selectedClientCarer && selectedClientCarer.id === item.id ? "success" : "light"}
                              selected={selectedClientCarer && selectedClientCarer.id === item.id}
                              onClick={() => handleSelectCarer(item)}
                            />
                          </Col>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </div>

                {showList ? (
                  <>
                    {isSwitchOn || (!isSwitchOn && selectedClientCarer?.id) ? (
                      <RotaListing
                        startDate={startDate}
                        endDate={endDate}
                        gradeId={grade?.id}
                        agencyId={agency?.id}
                        shiftType={shiftType}
                        type={showCarerList ? RotaType.carer : RotaType.hospital}
                        carerId={showCarerList ? selectedClientCarer?.id : ""}
                        clientId={!showCarerList ? selectedClientCarer?.id : ""}
                        status={status?.value || RotaOptions.open}
                        selectableRows={true}
                        onRowSelectionChange={(rows) => setSelectedRows(rows)}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <RotaList
                    startDate={startDate}
                    gradeId={grade?.id}
                    agencyId={agency?.id}
                    shiftType={shiftType}
                    endDate={endDate}
                    type={showCarerList ? RotaType.carer : RotaType.hospital}
                    carerId={showCarerList ? selectedClientCarer?.id : ""}
                    clientId={!showCarerList ? selectedClientCarer?.id : ""}
                    status={status?.value || RotaOptions.open}
                    selectableRows={true}
                    onRowSelectionChange={(rows) => setSelectedRows(rows)}
                  />
                )}
              </div>
            </PreviewCard>
          ) : (
            <PreviewAltCard>
              <span className="d-flex justify-content-center">You do not have permission</span>
            </PreviewAltCard>
          )}
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Rotas;
